import { createSlice } from "@reduxjs/toolkit";

export const dataSlice = createSlice({
  name: "data",
  initialState: {
    allDevices: [],
    selectedDevice: [],
    deviceListUpdate: true,
    deviceUpdate: true,

    // searchedDevices: [],
    displayedDevices: [],
    searched: false,
    // selected: false,
    zoom: 5,
    center: { lat: 33.6, lng: -96.5 },
    filters: {
      waterTransfer: false,
      tricon: false,
      J1939: false,
      online: false,
      connected: false,
      recent: false,
      needsAttention: false,
      offline: false,
    },
  },
  reducers: {
    setAllDevices: (state, action) => {
      state.allDevices = action.payload;
    },
    setSelectedDevice: (state, action) => {
      state.selectedDevice = action.payload;
    },
    setSearchedDevices: (state, action) => {
      state.searchedDevices = action.payload;
    },
    setDisplayedDevices: (state, action) => {
      state.displayedDevices = action.payload;
    },
    setSearched: (state, action) => {
      state.searched = action.payload;
    },
    setSelected: (state, action) => {
      state.selected = action.payload;
    },
    setZoom: (state, action) => {
      state.zoom = action.payload;
    },
    setCenter: (state, action) => {
      state.center = action.payload;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    setDeviceListUpdate: (state, action) => {
      state.deviceListUpdate = action.payload;
    },
    setDeviceUpdate: (state, action) => {
      state.deviceUpdate = action.payload;
    },
  },
});

export const {
  setAllDevices,
  setSelectedDevice,
  setDisplayedDevices,
  setSearched,
  setSelected,
  setSearchedDevices,
  setCenter,
  setZoom,
  setFilters,
  setDeviceListUpdate,
  setDeviceUpdate,
} = dataSlice.actions;

export default dataSlice.reducer;
