import React, { useState, useEffect } from "react";
import { FormControl } from "react-bootstrap";

const SearchBar = ({ onEquipmentSearch, onClearSearch, searchTerm }) => {
  const [query, setQuery] = useState(searchTerm);

  // Function to set the search value
  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };

  // Function to handle the search operation
  const handleSearch = () => {
    onEquipmentSearch(query);
  };

  // Handle enter key in search field - Scott Sr.
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      onEquipmentSearch(query);
    }
  };

  useEffect(() => {
    setQuery(searchTerm);
  }, [searchTerm]);

  // Function to clear the input
  const clearInput = () => {
    setQuery("");
    // onEquipmentSearch('')
    onClearSearch("");
  };
  return (
    <div style={{ width: 180, margin: "0 auto" }}>
      <div
        style={{
          float: "left",
          display: "flex",
          alignItems: "center",
          position: "relative",
          marginTop: 11,
        }}
      >
        <FormControl
          type="text"
          placeholder="Search"
          value={query}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
          style={{
            width: "100%",
            height: "30px",
            fontSize: "medium",
            padding: "5px",
            color: "#09a7ba",
          }}
        />
        {query && (
          <span
            style={{
              position: "absolute",
              right: 42,
              top: "52%",
              transform: "translateY(-50%)",
              color: "grey",
              cursor: "pointer",
            }}
            onClick={clearInput}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-x-circle"
              viewBox="0 0 16 16"
            >
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
          </span>
        )}
        <span
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
            width: "30px",
            cursor: "pointer",
          }}
          onClick={handleSearch}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            fill="#04FFF7"
            className="bi bi-search"
            viewBox="0 0 16 16"
          >
            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
          </svg>
        </span>
      </div>
    </div>
  );
};

export default SearchBar;
