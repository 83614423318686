import { configureStore, applyMiddleware } from '@reduxjs/toolkit';
import dataReducer from './redux/dataSlice';
import myReducer from './redux/currentPageSlice';
import urlMiddleware from './redux/urlMiddleware';  // Assuming you have your middleware defined here


export default configureStore({
    reducer: {
        data: dataReducer,
        currentPageSlice: myReducer

    },
    middleware: (getDefaultMiddleware) => {
        const defaultMiddleware = getDefaultMiddleware({
            serializableCheck: false,

        });

        return defaultMiddleware.concat(urlMiddleware);
    },

}
)