// /*!

// =========================================================
// * Light Bootstrap Dashboard PRO React - v1.2.0
// =========================================================

//
// Refactored to be a functional component instead of a class component
//

import React, { useEffect } from "react";
// import { Switch, Route } from "react-router-dom";

import Footer from "components/Footer/Footer.jsx";
// import AuthNavbar from "components/Navbars/AuthNavbar.jsx";
// import routes from "routes.jsx";

import bgImage from "assets/img/background.png";
import LoginPage from "../views/Pages/LoginPage";

const Pages = () => {

  useEffect(() => {
    // console.log(document.documentElement.className)
    if (document.documentElement.className.indexOf("nav-open") !== -1) {
      document.documentElement.classList.toggle("nav-open");
    }
  }, []);

  const getPageClass = () => {
    return " login-page";
  };

  // const getRoutes = routes => {
  //   return [];
  // };

  return (
    <div>
      <div className="wrapper wrapper-full-page">
        <div
          className={"full-page" + getPageClass()}
          data-color="black"
          data-image={bgImage}
        >
          <div className="content">
            <LoginPage />
          </div>
          <Footer transparent />
          <div
            className="full-page-background"
            style={{ backgroundImage: "url(" + bgImage + ")" }}
          />
        </div>
      </div>
    </div>
  );
};

export default Pages;

