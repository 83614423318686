
const urlMiddleware = store => next => action => {
    // Logic to update state based on URL can go here
    if (action.type === 'URL_CHANGE') { 
        let page;
        const hash = window.location.hash;
        const parts = hash.split('/');
        const pageSeg = parts[parts.length - 1];
        if (pageSeg === 'dashboard') {
            page = "dashboard"
        } else if (pageSeg === 'users') {
            page = "users"
        } else if (pageSeg === 'jobs') {
            page = "jobs"
        } else if (pageSeg === 'equipment') {
            page = "equipment"
        } else if (pageSeg === 'settings') {
            page = "settings"

        } else {
            page = "vehicleView"

        }
        store.dispatch({ type: 'currentPageSlice/changePage', payload: page }); // 'changePage' is the action to update the Redux state
    }
    
    return next(action);
};

export default urlMiddleware;
