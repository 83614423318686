import React, { Component } from "react";
import { Grid, Col, Row } from "react-bootstrap";

import Card from "components/Card/Card.jsx";

class ToDo extends Component {
	render() {
		function buildList(content) {
			return (<ul>
				{content.map(c => (<li>{c}</li>))}
			</ul>)
		}

		return (
			<div className="main-content">
				<Grid fluid>
					<Row>
						<Col>
							<Card
								title={this.props.title}
								category={this.props.subtitle || "Not Yet Implemented"}
								content={
									<div>
										To Do:
										{buildList(this.props.list)}
									</div>
								}
							/>
						</Col>
					</Row>
				</Grid>
			</div>
		);
	}
}

export default ToDo;
