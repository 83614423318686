import React, { useState, useEffect } from "react";
import { Navbar } from "react-bootstrap";
import AdminNavbarLinks from "./AdminNavbarLinks.jsx";
import routes from "routes.jsx";
import BrowserHistory from "models/BrowserHistory";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedDevice,
  setSearched,
  setDeviceListUpdate,
  setDeviceUpdate,
} from "../../redux/dataSlice";

const AdminNavbar = ({ navbar, handleMiniClick }) => {
  const currentPage = useSelector(
    (state) => state.currentPageSlice.currentPage
  );

  const dispatch = useDispatch();
  const handleNavClick = () => {
    //  dispatch(setSearched(true));
    dispatch(setSelectedDevice([]));
    dispatch(setDeviceListUpdate(true));
    dispatch(setDeviceUpdate(false));

    BrowserHistory.push("/admin/dashboard");
  };

  const getActiveRoute = (routes) => {
    let activeRoute = "DASHBOARD";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };

  const mobileSidebarToggle = (e) => {
    document.documentElement.classList.toggle("nav-open");
  };

  return (
    <Navbar fluid>
      <div className="navbar-minimize">
        <button
          id="minimizeSidebar"
          className="btn btn-default btn-fill btn-round btn-icon"
          onClick={handleMiniClick}
        >
          <i className="fa fa-ellipsis-v visible-on-sidebar-regular" />
          <i className="fa fa-navicon visible-on-sidebar-mini" />
        </button>
      </div>

      <Navbar.Header>
        <Navbar.Brand>
          {/* Here we create navbar brand, based on route name */}

          <div style={{ cursor: "pointer" }} onClick={handleNavClick}>
            {currentPage === "vehicleView" ? (
              <div style={{ color: "white", marginLeft: 12, float: "left" }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  fill="currentColor"
                  className="bi bi-arrow-left-square"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm11.5 5.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"
                  />
                </svg>
              </div>
            ) : (
              <div
                style={{ width: 20, height: 20, marginLeft: 10, float: "left" }}
              >
                {""}
              </div>
            )}

            <span
              style={{
                color: "white",
                paddingLeft: 5,
                textTransform: "uppercase",
              }}
            >
              {getActiveRoute(routes)}
            </span>
          </div>
        </Navbar.Brand>
        <Navbar.Toggle onClick={mobileSidebarToggle} />
      </Navbar.Header>

      {/* Here we import the links that appear in navbar */}
      {window.innerWidth > 992 ? (
        <Navbar.Collapse>
          <AdminNavbarLinks />
        </Navbar.Collapse>
      ) : null}
    </Navbar>
  );
};

export default AdminNavbar;
