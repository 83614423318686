import React from "react";

import { Router, Route, Switch, Redirect } from "react-router-dom";
import BrowserHistory from "models/BrowserHistory";

import "bootstrap/dist/css/bootstrap.min.css";
import "assets/sass/light-bootstrap-dashboard-pro-react.scss?v=1.2.0";
import "assets/css/demo.css";
import "assets/css/pe-icon-7-stroke.css";
import "assets/css/custom-light-bootstrap.css"; //tgs 20231023

import AuthLayout from "layouts/Auth.jsx";
import AdminLayout from "layouts/Admin.jsx";

function App() {
  return (
    <Router history={BrowserHistory}>
      <div className={"body-background-image"}></div>
      <Switch>
        <Route path="/auth" render={(props) => <AuthLayout props={props} />} />
        <Route
          path="/admin"
          render={(props) => <AdminLayout props={props} />}
        />
        <Route
          path="/admin/dashboard/:deviceId"
          render={(props) => <AdminLayout props={props} />}
        />
        <Redirect from="/" to="/admin/dashboard" />
      </Switch>
    </Router>
  );
}

export default App;
