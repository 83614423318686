import React, { useState } from "react";
import { Modal, FormControl, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setFilters } from "../../redux/dataSlice";

const MenuButton = ({ getFilters }) => {
  const filters = useSelector((state) => state.data.filters);
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [online, setOnline] = useState(filters.online);
  const [connected, setConnected] = useState(filters.connected);
  const [recent, setRecent] = useState(filters.recent);
  const [needsAttention, setNeedsAttention] = useState(filters.needsAttention);
  const [offline, setOffline] = useState(filters.offline);

  const logState = () => {
    const state = {
      online: online,
      connected: connected,
      recent: recent,
      needsAttention: needsAttention,
      offline: offline,
    };

    return state;
  };

  const handleShow = () => setShow(true);

  const handleCancel = (e) => {
    e.preventDefault();
    const state = {
      online: false,
      connected: false,
      recent: false,
      needsAttention: false,
      offline: false,
    };
    setOnline(false);
    setConnected(false);
    setRecent(false);
    setOffline(false);
    setNeedsAttention(false);

    dispatch(setFilters(state));
    getFilters(state);
    setShow(false);
  };
  const handleClose = (e) => {
    e.preventDefault();
    const state = logState();
    dispatch(setFilters(state));
    getFilters(state);
    setShow(false);
  };
  const onOnlineCheckChange = (e) => {
    setOnline(e.target.checked);
  };
  const onConnectedCheckChange = (e) => {
    setConnected(e.target.checked);
  };
  const onRecentCheckChange = (e) => {
    setRecent(e.target.checked);
  };
  const onNeedsAttentionCheckChange = (e) => {
    setNeedsAttention(e.target.checked);
  };
  const onOfflineCheckChange = (e) => {
    setOffline(e.target.checked);
  };
  return (
    <>
      <div className="dashboard-filter-btn">
        <button
          onClick={handleShow}
          style={{ display: "flex", alignItems: "center" }}
        >
          {/* <span> */}
          <svg width="20" height="20" viewBox="0 0 50 50">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g fill="#000000">
                <g transform="translate(-0.000000, 0.000000)">
                  <path d="M19.7101213,26 C18.8495721,28.8914889 16.1710206,31 13,31 C9.82897936,31 7.15042787,28.8914889 6.28987868,26 L2.00138927,26 C0.896052496,26 0,25.1122704 0,24 C0,22.8954305 0.894938439,22 2.00138927,22 L6.28987868,22 C7.15042787,19.1085111 9.82897936,17 13,17 C16.1710206,17 18.8495721,19.1085111 19.7101213,22 L45.9986107,22 C47.1039475,22 48,22.8877296 48,24 C48,25.1045695 47.1050616,26 45.9986107,26 L19.7101213,26 Z M28.2898787,39 C29.1504279,36.1085111 31.8289794,34 35,34 C38.1710206,34 40.8495721,36.1085111 41.7101213,39 L45.9986107,39 C47.1039475,39 48,39.8877296 48,41 C48,42.1045695 47.1050616,43 45.9986107,43 L41.7101213,43 C40.8495721,45.8914889 38.1710206,48 35,48 C31.8289794,48 29.1504279,45.8914889 28.2898787,43 L2.00138927,43 C0.896052496,43 -7.62286386e-16,42.1122704 -4.8985872e-16,41 C-2.19317219e-16,39.8954305 0.894938439,39 2.00138927,39 L28.2898787,39 Z M28.2898787,5 C29.1504279,2.10851105 31.8289794,-8.38527375e-16 35,-6.18513206e-17 C38.1710206,7.14824734e-16 40.8495721,2.10851105 41.7101213,5 L45.9986107,5 C47.1039475,5 48,5.88772964 48,7 C48,8.1045695 47.1050616,9 45.9986107,9 L41.7101213,9 C40.8495721,11.8914889 38.1710206,14 35,14 C31.8289794,14 29.1504279,11.8914889 28.2898787,9 L2.00138927,9 C0.896052496,9 -7.62286386e-16,8.11227036 -4.8985872e-16,7 C-2.19317219e-16,5.8954305 0.894938439,5 2.00138927,5 L28.2898787,5 Z M35,10 C36.6568542,10 38,8.65685425 38,7 C38,5.34314575 36.6568542,4 35,4 C33.3431458,4 32,5.34314575 32,7 C32,8.65685425 33.3431458,10 35,10 Z M35,44 C36.6568542,44 38,42.6568542 38,41 C38,39.3431458 36.6568542,38 35,38 C33.3431458,38 32,39.3431458 32,41 C32,42.6568542 33.3431458,44 35,44 Z M13,21 C11.3431458,21 10,22.3431458 10,24 C10,25.6568542 11.3431458,27 13,27 C14.6568542,27 16,25.6568542 16,24 C16,22.3431458 14.6568542,21 13,21 Z" />
                </g>
              </g>
            </g>
          </svg>
          {/* </span> */}
          <span style={{ marginLeft: "8px" }}>FILTER</span>
        </button>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        className="menu-modal"
        backdrop="static" // Prevents closing the modal when clicking outside of it
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title style={{ color: "black" }}>FILTERS</Modal.Title>
          <button
            type="button"
            className="modal-cancel-button"
            onClick={(e) => handleCancel(e)}
          >
            {"CANCEL"}
          </button>

          <button
            type="button"
            className="modal-apply-button"
            onClick={(e) => handleClose(e)}
          >
            {"APPLY"}
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-sections-container">
            <div className="modal-section">
              <Form>
                {/* Online Checkbox */}
                <div className="checkbox-group">
                  <FormControl
                    type="checkbox"
                    id="checkboxOnline"
                    onChange={onOnlineCheckChange}
                    defaultChecked={online}
                    className="small-checkbox"
                  />
                  <label htmlFor="checkboxOnline">ONLINE</label>
                </div>
                {/* Connected Checkbox */}
                <div className="checkbox-group">
                  <FormControl
                    type="checkbox"
                    id="checkboxConnected"
                    onChange={onConnectedCheckChange}
                    defaultChecked={connected}
                    className="small-checkbox"
                  />
                  <label htmlFor="checkboxConnected">CONNECTED</label>
                </div>
                {/* Offline Checkbox */}
                <div className="checkbox-group">
                  <FormControl
                    type="checkbox"
                    id="checkboxOffline"
                    onChange={onOfflineCheckChange}
                    defaultChecked={offline}
                    className="small-checkbox"
                  />
                  <label htmlFor="checkboxOffline">OFFLINE</label>
                </div>
              </Form>
            </div>

            <div className="modal-section">
              <Form>
                {/* Needs Attention Checkbox */}
                <div className="checkbox-group">
                  <FormControl
                    type="checkbox"
                    id="checkboxNeedsAttention"
                    onChange={onNeedsAttentionCheckChange}
                    defaultChecked={needsAttention}
                    className="small-checkbox"
                  />
                  <label htmlFor="checkboxNeedsAttention">
                    NEEDS ATTENTION
                  </label>
                </div>
                {/* Recent Checkbox */}
                <div className="checkbox-group">
                  <FormControl
                    type="checkbox"
                    id="checkboxRecent"
                    onChange={onRecentCheckChange}
                    defaultChecked={recent}
                    className="small-checkbox"
                  />
                  <label htmlFor="checkboxRecent">
                    {"RECENT (LAST 7 DAYS)"}
                  </label>
                </div>
              </Form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default MenuButton;
