import React, { useEffect, useState, useRef } from "react";
import { VictoryChart, VictoryLine, VictoryAxis, VictoryLabel } from "victory";

import { get_historical_data } from "../../models/api";
import { get_series_select } from "../../models/api";
import moment from "moment";
import "./chartview.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./datepicker.css"; // Path to your custom CSS file
import { useSelector } from "react-redux";
import Checkbox from "components/CustomCheckbox/CustomCheckbox.jsx";

const ChartView = ({ deviceUpdateNum }) => {
  const intervalSelectionRef = useRef(null);

  const [errorMessages, setErrorMessages] = useState([]);

  const [chartLeftAxis, setChartLeftAxis] = useState(true);
  const [chartLeftAxis_2, setChartLeftAxis_2] = useState(true);
  const [chartLeftAxis_3, setChartLeftAxis_3] = useState(true);

  const [chartRightAxis, setChartRightAxis] = useState(false);
  const [chartRightAxis_2, setChartRightAxis_2] = useState(false);
  const [chartRightAxis_3, setChartRightAxis_3] = useState(false);

  const [showLeftAxis] = useState(true);
  const [showLeftAxis_2] = useState(true);
  const [showLeftAxis_3] = useState(true);

  const [showRightAxis, setShowRightAxis] = useState(false);
  const [showRightAxis_2, setShowRightAxis_2] = useState(false);
  const [showRightAxis_3, setShowRightAxis_3] = useState(false);

  //
  // ATTENTION!! set checkbox and chart defaults here!
  //
  const [selectedTypes, setSelectedTypes] = useState(["Eng Oil Pressure"]);
  const [selectedTypes_2, setSelectedTypes_2] = useState(["Eng Fuel Rate"]);
  const [selectedTypes_3, setSelectedTypes_3] = useState([
    "Eng Battery Voltage",
  ]);
  const [selectedSpnNumbers, setSelectedSpnNumbers] = useState([
    "SPN_000100_0_255",
  ]);
  const [selectedSpnNumbers_2, setSelectedSpnNumbers_2] = useState([
    "SPN_000183_0_255",
  ]);
  const [selectedSpnNumbers_3, setSelectedSpnNumbers_3] = useState([
    "SPN_000168_0_255",
  ]);
  const [umDisplay, setUmDisplay] = useState(["PSI"]);
  const [umDisplay_2, setUmDisplay_2] = useState(["GALLONS PER HOUR"]);
  const [umDisplay_3, setUmDisplay_3] = useState(["VOLTS"]);

  // this is a hack for when DASHBOARD is clicked from AdminNavbar.jsx to keep app from failing.
  // redux will have taken the device away so, restore sessionStorage version.
  const device =
    useSelector((state) => state.data.selectedDevice)[0] ||
    JSON.parse(sessionStorage.getItem("chart-device"));

  if (device) {
    sessionStorage.setItem("chart-device", JSON.stringify(device));
  }
  const shouldShowJ1939 = () => {
    const hasJ1939 = device.j1939Time && device.j1939Snapshot;
    const hasTricon = !!device.triconTime;
    return (
      hasJ1939 &&
      (!hasTricon || device.j1939Time.diff(device.triconTime, "hours") > -2)
    );
  };
  const [dataValue, setDataValue] = useState(
    shouldShowJ1939(device) ? ["SPN_000100_0_255"] : ["TRI_000"]
  );
  const [dataValue_2, setDataValue_2] = useState(
    shouldShowJ1939(device) ? ["SPN_000183_0_255"] : ["TRI_057"]
  );
  const [dataValue_3, setDataValue_3] = useState(
    shouldShowJ1939(device) ? ["SPN_000168_0_255"] : ["TRI_005"]
  );

  const [chartData, setChartData] = useState([[], []]);
  const [chartData_2, setChartData_2] = useState([[], []]);
  const [chartData_3, setChartData_3] = useState([[], []]);
  const [chartName, setChartName] = useState(["", ""]);
  const [chartName_2, setChartName_2] = useState(["", ""]);
  const [chartName_3, setChartName_3] = useState(["", ""]);

  const [domain, setDomain] = useState([
    [0, 1000],
    [0, 1000],
  ]);
  const [domain_2, setDomain_2] = useState([
    [0, 1000],
    [0, 1000],
  ]);
  const [domain_3, setDomain_3] = useState([
    [0, 1000],
    [0, 1000],
  ]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isExpanded_2, setIsExpanded_2] = useState(false);
  const [isExpanded_3, setIsExpanded_3] = useState(false);

  const [optionsData, setOptionsData] = useState([]);
  const [optionsData_2, setOptionsData_2] = useState([]);
  const [optionsData_3, setOptionsData_3] = useState([]);
  const [subCheckboxStates, setSubCheckboxStates] = useState(
    shouldShowJ1939(device)
      ? {
          "Eng Oil Pressure": { checked: true, color: "red" },
          // "Eng Fuel Rate": { checked: true, color: "blue" },
        }
      : {
          "Eng Oil Pressure": { checked: true, color: "red" },
          // "Eng Fuel Rate": { checked: true, color: "blue" },
        }
  );
  const [subCheckboxStates_2, setSubCheckboxStates_2] = useState(
    shouldShowJ1939(device)
      ? {
          "Eng Fuel Rate": { checked: true, color: "red" },
        }
      : {
          "Eng Fuel Rate": { checked: true, color: "red" },
        }
  );
  const [subCheckboxStates_3, setSubCheckboxStates_3] = useState(
    shouldShowJ1939(device)
      ? {
          "Eng Battery Voltage": { checked: true, color: "red" },
        }
      : {
          "Eng Voltage": { checked: true, color: "red" },
        }
  );
  const [redChecked, setRedChecked] = useState(
    shouldShowJ1939(device) ? "Eng Oil Pressure" : "Eng Oil Pressure"
  );

  const [redChecked_2, setRedChecked_2] = useState(
    shouldShowJ1939(device) ? "Eng Fuel Rate" : "Eng Fuel Rate"
  );

  //Eng Battery Voltage
  const [redChecked_3, setRedChecked_3] = useState(
    shouldShowJ1939(device) ? "Eng Battery Voltage" : "Eng Voltage"
  );

  const [blueChecked, setBlueChecked] = useState(null);
  const [blueChecked_2, setBlueChecked_2] = useState(null);
  const [blueChecked_3, setBlueChecked_3] = useState(null);

  const [leftOrRight, setLeftOrRight] = useState("left");
  const [leftOrRight_2, setLeftOrRight_2] = useState("left");
  const [leftOrRight_3, setLeftOrRight_3] = useState("left");

  //const [isMustang, setIsMustang] = useState(false);
  const [autoUpdate, setAutoUpdate] = useState(true);

  const [startDate, setStartDate] = useState(
    moment().subtract(2, "hours").toDate()
  );
  const [endDate, setEndDate] = useState(moment().toDate());
  const [adjDate] = useState(moment().toDate());
  const [intervalSelection, setIntervalSelection] = useState("30");

  const [triDescriptionsAry] = useState([
    {
      spnNumber: "TRI_000",
      description: "Eng RPM",
      umdisplay: "RPM",
    },
    {
      spnNumber: "TRI_001",
      description: "Eng Oil Temp",
      umdisplay: "FARHENHEIT",
    },
    {
      spnNumber: "TRI_002",
      description: "Eng Oil Pressure",
      umdisplay: "PSI",
    },
    {
      spnNumber: "TRI_004",
      description: "Eng Fuel Temp",
      umdisplay: "FARHENHEIT",
    },
    {
      spnNumber: "TRI_005",
      description: "Eng Voltage",
      umdisplay: "VOLTS",
    },
    {
      spnNumber: "TRI_006",
      description: "Eng Coolant Temp",
      umdisplay: "FARHENHEIT",
    },
    {
      spnNumber: "TRI_023",
      description: "Eng Hours",
      umdisplay: "HOURS",
    },
    {
      spnNumber: "TRI_057",
      description: "Eng Fuel Rate",
      umdisplay: "GALLONS PER HOUR",
    },
    {
      spnNumber: "TRI_133",
      description: "Eng Throttle",
      umdisplay: "PERCENT",
    },
    {
      spnNumber: "TRI_008",
      description: "Trans Oil Pressure",
      umdisplay: "PSI",
    },
    {
      spnNumber: "TRI_009",
      description: "Trans Oil Temp",
      umdisplay: "FARHENHEIT",
    },
    {
      spnNumber: "TRI_010",
      description: "Trans Gear",
      umdisplay: "RPM",
    },
    {
      spnNumber: "TRI_013",
      description: "Trans Output RPM",
      umdisplay: "RPM",
    },
    {
      spnNumber: "TRI_015",
      description: "Pump Output Pressure",
      umdisplay: "PSI",
    },
    {
      spnNumber: "TRI_016",
      description: "Pump Oil Pressure",
      umdisplay: "PSI",
    },
    {
      spnNumber: "TRI_017",
      description: "Pump Oil Temp",
      umdisplay: "FARHENHEIT",
    },
    {
      spnNumber: "TRI_022",
      description: "Pump Rate BPM",
      umdisplay: "BARRELS PER MINUTE",
    },
    {
      spnNumber: "TRI_053",
      description: "Pump Hours",
      umdisplay: "HOURS",
    },
    {
      spnNumber: "TRI_136",
      description: "Pump Total Barrels",
      umdisplay: "BARRELS",
    },
    {
      spnNumber: "TRI_137",
      description: "Pump Smooth Pressure",
      umdisplay: "PSI",
    },
    {
      spnNumber: "TRI_018",
      description: "Hydraulics Main Pressure",
      umdisplay: "PSI",
    },
    {
      spnNumber: "TRI_019",
      description: "Hydraulics Charge Pressure",
      umdisplay: "PSI",
    },
    {
      spnNumber: "TRI_020",
      description: "Hydraulics Fluid Temp",
      umdisplay: "FARHENHEIT",
    },
    {
      spnNumber: "TRI_038",
      description: "Misc Fuel Indicator Level%",
      umdisplay: "PERCENT",
    },
    {
      spnNumber: "TRI_055",
      description: "Misc Intercooler Fan Speed CTL",
      umdisplay: "RPM",
    },
  ]);
  const [spnDescriptionsAry] = useState([
    {
      spnNumber: "SPN_000190_0_255",
      description: "Eng RPM",
      umdisplay: "RPM",
    },
    {
      spnNumber: "SPN_000100_0_255",
      description: "Eng Oil Pressure",
      umdisplay: "PSI",
    },
    {
      spnNumber: "SPN_000110_0_255",
      description: "Eng Coolant Temp",
      umdisplay: "FARHENHEIT",
    },
    {
      spnNumber: "SPN_000174_0_255",
      description: "Eng Fuel Temp",
      umdisplay: "FARHENHEIT",
    },
    // {
    //     spnNumber: 'SPN_000094_0_255',
    //     description: 'Eng Fuel Pressure',
    //     umdisplay: 'PSI'
    // },
    {
      spnNumber: "SPN_000183_0_255",
      description: "Eng Fuel Rate",
      umdisplay: "GALLONS PER HOUR",
    },
    {
      spnNumber: "SPN_000102_0_255",
      description: "Eng Intake Manifold Pressure",
      umdisplay: "PSI",
    },
    {
      spnNumber: "SPN_000105_0_255",
      description: "Eng Intake Manifold Temp",
      umdisplay: "FARHENHEIT",
    },
    {
      spnNumber: "SPN_000168_0_255",
      description: "Eng Battery Voltage",
      umdisplay: "VOLTS",
    },
    {
      spnNumber: "SPN_000247_0_255",
      description: "Eng Total Hours",
      umdisplay: "HOURS",
    },
    {
      spnNumber: "SPN_000092_0_255",
      description: "Eng Load %",
      umdisplay: "PERCENT",
    },
    {
      spnNumber: "SPN_000091_0_255",
      description: "Eng Throttle %",
      umdisplay: "PERCENT",
    },
    {
      spnNumber: "SPN_000518_23_0",
      description: "Eng Requested Torque/Torque Limit",
      umdisplay: "PERCENT",
    },
    // {
    //     spnNumber: 'SPN_000127_0_255',
    //     description: 'Trans Oil Pressure',
    //     umdisplay: 'PSI'
    // },
    // {
    //     spnNumber: 'SPN_000177_0_255',
    //     description: 'Trans Oil Temp',
    //     umdisplay: 'FARHENHEIT'
    // },
    // {
    //     spnNumber: 'SPN_000523_0_255',
    //     description: 'Trans Gear',
    //     umdisplay: 'RPM'
    // },
    // {
    //     spnNumber: 'SPN_000573_0_255',
    //     description: 'Trans Lock-Up',
    //     umdisplay: '0=Disengaged / 1=Engaged'
    // },
    // {
    //     spnNumber: 'SPN_000161_0_255',
    //     description: 'Trans Input RPM',
    //     umdisplay: 'RPM'
    // },
    // {
    //     spnNumber: 'SPN_000191_0_255',
    //     description: 'Trans Output RPM',
    //     umdisplay: 'RPM'
    // },
    // {
    //     spnNumber: 'SPN_090002_23_255',
    //     description: 'Pump Suction Pressure',
    //     umdisplay: 'PSI'
    // },
    // {
    //     spnNumber: 'SPN_090001_23_255',
    //     description: 'Pump Fuel Level',
    //     umdisplay: 'PERCENT'
    // },
    // {
    //     spnNumber: 'SPN_090003_23_255',
    //     description: 'Pump Working Pressure',
    //     umdisplay: 'PSI'
    // },
    // {
    //     spnNumber: 'SPN_090004_23_255',
    //     description: 'Pump Rate',
    //     umdisplay: ''
    // }
  ]);

  //this is the change event for the left and right checkboxes that control the Y axis on the charts
  //
  const handleChartAxisCheckboxChange = (axis, chartNum) => {
    if (chartNum === 1) {
      if (axis === "left") {
        setChartLeftAxis(true);
        setChartRightAxis(false);
        setLeftOrRight("left");
      } else if (axis === "right") {
        setChartLeftAxis(false);
        setChartRightAxis(true);
        setLeftOrRight("right");
      }
    } else if (chartNum === 2) {
      if (axis === "left") {
        setChartLeftAxis_2(true);
        setChartRightAxis_2(false);
        setLeftOrRight_2("left");
      } else if (axis === "right") {
        setChartLeftAxis_2(false);
        setChartRightAxis_2(true);
        setLeftOrRight_2("right");
      }
    } else if (chartNum === 3) {
      if (axis === "left") {
        setChartLeftAxis_3(true);
        setChartRightAxis_3(false);
        setLeftOrRight_3("left");
      } else if (axis === "right") {
        setChartLeftAxis_3(false);
        setChartRightAxis_3(true);
        setLeftOrRight_3("right");
      }
    }
  };

  //This formats the X axis of the charts
  const formatXAxisTick = (tick, num) => {
    return moment(tick).format("MM/DD HH:mma");
  };
  //Get the data for CHART 1
  const handleDataFetch = async (e) => {
    let metricType;
    let position;

    //check whether the chart axis left or right checkbox is selected.
    //The data passed in is an array where the 0 index is the left checkbox data
    //and the 1 index is the right checkbox data, this determines if we are fetching data from the right axis or left axis.

    const axisCount = 2;

    for (let w = 0; w < axisCount; w++) {
      // console.log(w);
      metricType = e.target.value[w];
      position = w;

      let chartData_ = [];
      let selectedText;

      selectedText = selectedTypes[position] || "PSI";

      await get_historical_data(
        device.id,
        metricType,
        moment(startDate).utc().format("YYYY-MM-DDTHH:mm:ss[Z]"),
        moment(endDate).utc().format("YYYY-MM-DDTHH:mm:ss[Z]"),
        intervalSelection
      ).then((response) => {
        console.log(response);
        if (!response) {
          return;
        }

        if (response === "error") {
          errorMessages.push(response);

          return;
        }
        const historyData = response.historical_data.data;
        historyData.forEach((element) => {
          Object.keys(element).forEach((key) => {
            if (key !== "time") {
              const uom = getUmDisplayBySpnNumber(key);

              //   const spnNumber = key;
              const value = element[key];
              let convertedValue = value;
              if (uom === "FARHENHEIT") {
                convertedValue = value * 1.8 + 32;
              } else if (uom === "PSI") {
                convertedValue = value * 0.145038;
              } else if (uom === "GALLONS PER HOUR") {
                convertedValue = value * 0.264172;
              }

              element[key] = convertedValue;

              // If you need to use the SPN number and value as variables elsewhere
              // let spnVariable = spnNumber;
              // let valueVariable = convertedValue;

              // Log the SPN and the converted value or return them if needed
              //  console.log(spnNumber, convertedValue);
            }
          });

          if (element[metricType]) {
            return chartData_.push({
              x: moment(element.time * 1000)
                .utc()
                .toDate(),
              y: element[metricType],
            });
          }
        });

        //This creates the Y axis values on the chart based on the data passed in
        generateTickValues(chartData_, 1, w);

        //Since dataValue is an array consiting of the left(index 0) and right(index 1) axis data
        //we need to replace the correct index basedon the axis targeted.
        //The same goes for chartName and ChartData
        setDataValue((prev) => {
          const updated = [...prev];
          updated[position] = metricType;
          return updated;
        });

        setChartName((prev) => {
          const updated = [...prev];
          updated[position] = selectedText;
          return updated;
        });
        setChartData((prev) => {
          const updated = [...prev];
          updated[position] = chartData_;
          return updated;
        });
      });
    }
  };

  //Get the data for CHART 2
  const handleDataFetch_2 = async (e) => {
    let metricType;
    let position;

    //check whether the chart axis left or right checkbox is selected.
    //The data passed in is an array where the 0 index is the left checkbox data
    //and the 1 index is the right checkbox data, this determines if we are fetching data from the right axis or left axis.
    const axisCount = 2;

    for (let w = 0; w < axisCount; w++) {
      console.log(w);
      metricType = e.target.value[w];
      position = w;
      let chartData_ = [];
      let selectedText;

      selectedText = selectedTypes_2[position] || "RPM";

      await get_historical_data(
        device.id,
        metricType,
        moment(startDate).utc().format("YYYY-MM-DDTHH:mm:ss[Z]"),
        moment(endDate).utc().format("YYYY-MM-DDTHH:mm:ss[Z]"),
        intervalSelection
      ).then((response) => {
        if (!response) {
          return;
        }
        if (response === "error") {
          errorMessages.push(response);

          return;
        }
        const historyData = response.historical_data.data;
        historyData.forEach((element) => {
          Object.keys(element).forEach((key) => {
            if (key !== "time") {
              const uom = getUmDisplayBySpnNumber(key);
              //  console.log(uom)
              // const spnNumber = key;
              const value = element[key];
              let convertedValue = value;
              if (uom === "FARHENHEIT") {
                convertedValue = value * 1.8 + 32;
              } else if (uom === "PSI") {
                convertedValue = value * 0.145038;
              } else if (uom === "GALLONS PER HOUR") {
                convertedValue = value * 0.264172;
              }

              element[key] = convertedValue;

              // If you need to use the SPN number and value as variables elsewhere
              // let spnVariable = spnNumber;
              // let valueVariable = convertedValue;

              // Log the SPN and the converted value or return them if needed
              //   console.log(spnNumber, convertedValue);
            }
          });

          if (element[metricType]) {
            return chartData_.push({
              x: moment(element.time * 1000)
                .utc()
                .toDate(),
              y: element[metricType],
            });
          }
        });
        console.log(chartData_);
        generateTickValues(chartData_, 2, w);

        //Since dataValue is an array consiting of the left(index 0) and right(index 1) axis data
        //we need to replace the correct index based on the axis targeted.
        //The same goes for chartName and ChartData
        setDataValue_2((prev) => {
          const updated = [...prev];
          updated[position] = metricType;
          return updated;
        });

        setChartName_2((prev) => {
          const updated = [...prev];
          updated[position] = selectedText;
          return updated;
        });
        setChartData_2((prev) => {
          const updated = [...prev];
          updated[position] = chartData_;
          //  console.log(updated);
          return updated;
        });
      });
    }
  };

  //Get the data for CHART 2
  const handleDataFetch_3 = async (e) => {
    let metricType;
    let position;

    //check whether the chart axis left or right checkbox is selected.
    //The data passed in is an array where the 0 index is the left checkbox data
    //and the 1 index is the right checkbox data, this determines if we are fetching data from the right axis or left axis.
    const axisCount = 2;

    for (let w = 0; w < axisCount; w++) {
      console.log(w);
      metricType = e.target.value[w];
      position = w;
      let chartData_ = [];
      let selectedText;
      selectedText = selectedTypes_3[position] || "VOLTS";

      await get_historical_data(
        device.id,
        metricType,
        moment(startDate).utc().format("YYYY-MM-DDTHH:mm:ss[Z]"),
        moment(endDate).utc().format("YYYY-MM-DDTHH:mm:ss[Z]"),
        intervalSelection
      ).then((response) => {
        if (!response) {
          return;
        }
        if (response === "error") {
          errorMessages.push(response);

          return;
        }
        const historyData = response.historical_data.data;
        historyData.forEach((element) => {
          Object.keys(element).forEach((key) => {
            if (key !== "time") {
              const uom = getUmDisplayBySpnNumber(key);

              // const spnNumber = key;
              const value = element[key];
              let convertedValue = value;
              if (uom === "FARHENHEIT") {
                convertedValue = value * 1.8 + 32;
              } else if (uom === "PSI") {
                convertedValue = value * 0.145038;
              } else if (uom === "GALLONS PER HOUR") {
                convertedValue = value * 0.264172;
              }

              element[key] = convertedValue;

              // If you need to use the SPN number and value as variables elsewhere
              // let spnVariable = spnNumber;
              // let valueVariable = convertedValue;

              // Log the SPN and the converted value or return them if needed
              //  console.log(spnNumber, convertedValue);
            }
          });

          if (element[metricType]) {
            return chartData_.push({
              x: moment(element.time * 1000)
                .utc()
                .toDate(),
              y: element[metricType],
            });
          }
        });

        generateTickValues(chartData_, 3, w);

        //Since dataValue is an array consiting of the left(index 0) and right(index 1) axis data
        //we need to replace the correct index basedon the axis targeted.
        //The same goes for chartName and ChartData
        setDataValue_3((prev) => {
          const updated = [...prev];
          updated[position] = metricType;
          return updated;
        });

        setChartName_3((prev) => {
          const updated = [...prev];
          updated[position] = selectedText;
          return updated;
        });
        setChartData_3((prev) => {
          const updated = [...prev];
          updated[position] = chartData_;
          return updated;
        });
      });
    }

    //
    // HERE is where we display the error messgae. Each graph logs the
    // errors and then a singular message is displayed.
    //
    if (errorMessages.length > 0) {
      alert(
        "Query may return too many results - Select a longer time interval."
      );

      //focus the time interval
      if (intervalSelectionRef.current) {
        intervalSelectionRef.current.focus();
      }
    }
  };

  const generateTickValues = (data, tix, w) => {
    console.log();
    if (!data || data.length < 2) {
      // alert("No Data Provided");
      return [];
    }

    const result = data.reduce(
      (acc, obj) => {
        if (obj.y > acc.max) acc.max = obj.y;
        if (obj.y < acc.min) acc.min = obj.y;
        return acc;
      },
      { max: -Infinity, min: Infinity }
    );

    const lastYTick = Math.ceil(result.max) + 1;
    const firstYTick = Math.floor(result.min) - 1;
    const domain = [firstYTick, lastYTick];

    if (tix === 1) {
      let position = w;

      setDomain((prev) => {
        const updated = [...prev];

        updated[position] = domain;

        return updated;
      });
    } else if (tix === 2) {
      let position = w;
      if (leftOrRight_2 === "left") {
        position = 0;
      } else {
        position = 1;
      }
      setDomain_2((prev) => {
        const updated = [...prev];

        updated[position] = domain;

        return updated;
      });
    } else if (tix === 3) {
      let position = w;
      if (leftOrRight_3 === "left") {
        position = 0;
      } else {
        position = 1;
      }
      setDomain_3((prev) => {
        const updated = [...prev];

        updated[position] = domain;

        return updated;
      });
    }
  };

  const getNowDates = () => {
    setStartDate(moment().subtract(2, "hours").toDate());
    setEndDate(moment().toDate());
  };

  // Change handlers for date pickers
  const handleStartDateChange = (date) => {
    setAutoUpdate(false); // Disable automatic updates
    setStartDate(date);
    if (startDate >= endDate) {
      window.alert("Start Time cannot be set after End Time");
      adjDate.setHours(endDate.getHours() - 2);
      setStartDate(adjDate);
      setAutoUpdate(true);
    }
  };

  const handleEndDateChange = (date) => {
    setAutoUpdate(false); // Disable automatic updates

    if (date <= startDate) {
      window.alert("End Time cannot be set prior Start Time");
      adjDate.setHours(startDate.getHours() + 2);
      setAutoUpdate(true);
    } else if (date > new Date()) {
      window.alert("End Time cannot be set beyond the current time");
      // setAdjDate(new Date());
      setEndDate(new Date());
      setAutoUpdate(true);
    } else {
      setEndDate(date);
    }
  };

  // const vehicleId = 186; // Replace with the actual ID you need to use
  const vehicleId = device.id; // Replace with the actual ID you need to use
  const startDt =
    //  "2024-02-01T00:00:00Z" ||
    "2024-06-17T15:30:00Z"; // Replace with the actual start date you need

  useEffect(() => {
    const spnDescriptionMap = {
      SPN_000190_0_255: "Eng RPM",
      SPN_000100_0_255: "Eng Oil Pressure",
      SPN_000110_0_255: "Eng Coolant Temp",
      SPN_000174_0_255: "Eng Fuel Temp",
      // SPN_000094_0_255: 'Eng Fuel Pressure',
      SPN_000183_0_255: "Eng Fuel Rate",
      SPN_000102_0_255: "Eng Intake Manifold Pressure",
      SPN_000105_0_255: "Eng Intake Manifold Temp",
      SPN_000168_0_255: "Eng Battery Voltage",
      SPN_000247_0_255: "Eng Total Hours",
      SPN_000092_0_255: "Eng Load %",
      SPN_000091_0_255: "Eng Throttle %",
      SPN_000518_23_0: "Eng Requested Torque/Torque Limit",
      // SPN_000127_0_255: 'Trans Oil Pressure',
      // SPN_000177_0_255: 'Trans Oil Temp',
      // SPN_000523_0_255: 'Trans Gear',
      // SPN_000573_0_255: 'Trans Lock-Up',
      // SPN_000161_0_255: 'Trans Input RPM',
      // SPN_000191_0_255: 'Trans Output RPM',
      // SPN_090002_23_255: 'Pump Suction Pressure',
      SPN_090001_23_255: "Pump Fuel Level",
      // SPN_090003_23_255: 'Pump Working Pressure',
      // SPN_090004_23_255: 'Pump Rate',
    };

    const triDescriptions = ["Eng RPM", "Eng Oil Temp", "Eng Oil Pressure"];

    if (device.kind.toLowerCase().includes("mustang")) {
      // setIsMustang(true);

      get_series_select(
        device.id,
        moment(startDate).utc().format("YYYY-MM-DDTHH:mm:ss[Z]")
      )
        .then((seriesData) => {
          console.log(seriesData);
          const filteredSeries = seriesData
            .filter(
              (series) =>
                series.spn !== null &&
                spnDescriptionMap[series.key] !== undefined
            )
            .map((series) => ({
              spnNumber: series.key,
              description: spnDescriptionMap[series.key], // Use the custom description
            }));

          // Sort filteredSeries based on the order of keys in spnDescriptionMap
          const sortedFilteredSeries = Object.keys(spnDescriptionMap).reduce(
            (acc, key) => {
              const foundSeries = filteredSeries.find(
                (series) => series.spnNumber === key
              );
              if (foundSeries) acc.push(foundSeries);
              return acc;
            },
            []
          );

          console.log("Sorted filtered series data:", sortedFilteredSeries);

          console.log(spnDescriptionsAry);
          //Set Options for mustang devices
          setOptionsData(spnDescriptionsAry);
          setOptionsData_2(spnDescriptionsAry);
          setOptionsData_3(spnDescriptionsAry);

          const spnOrder = Object.keys(spnDescriptionMap); // Get the order of SPNs as they appear in spnDescriptionMap
          const sortedFilteredDescriptions = filteredSeries.sort((a, b) => {
            return (
              spnOrder.indexOf(a.spnNumber) - spnOrder.indexOf(b.spnNumber)
            );
          });

          //   // Step 2: Create a new array containing just the descriptions, maintaining the sequence
          //   const descriptionsOnly = sortedFilteredDescriptions.map(
          //     (item) => item.description
          //   );

          //  setDataTypes(descriptionsOnly);
        })
        .catch((error) => {
          console.error("Error fetching series select data:", error);
        });
    } else {
      //Set Options for non mustang devices
      setOptionsData(triDescriptionsAry);
      setOptionsData_2(triDescriptionsAry);
      setOptionsData_3(triDescriptionsAry);
    }
  }, [device.id, startDt]);

  useEffect(() => {
    if (autoUpdate) {
      getNowDates();
      handleDataFetch({ target: { value: dataValue } });
    }
  }, [
    deviceUpdateNum,
    intervalSelection,
    selectedSpnNumbers[0],
    selectedSpnNumbers[1],
  ]);
  useEffect(
    (prevProps) => {
      if (!autoUpdate) {
        handleDataFetch({ target: { value: dataValue } });
      }
    },
    [
      startDate,
      endDate,
      intervalSelection,
      selectedSpnNumbers[0],
      selectedSpnNumbers[1],
    ]
  );

  useEffect(() => {
    if (autoUpdate) {
      getNowDates();
      handleDataFetch_2({ target: { value: dataValue_2 } });
    }
  }, [
    deviceUpdateNum,
    intervalSelection,
    selectedSpnNumbers_2[0],
    selectedSpnNumbers_2[1],
  ]);
  useEffect(
    (prevProps) => {
      if (!autoUpdate) {
        handleDataFetch_2({ target: { value: dataValue_2 } });
      }
    },
    [
      startDate,
      endDate,
      intervalSelection,
      selectedSpnNumbers_2[0],
      selectedSpnNumbers_2[1],
    ]
  );
  useEffect(() => {
    if (autoUpdate) {
      getNowDates();
      handleDataFetch_3({ target: { value: dataValue_3 } });
    }
  }, [
    deviceUpdateNum,
    intervalSelection,
    selectedSpnNumbers_3[0],
    selectedSpnNumbers_3[1],
  ]);
  useEffect(
    (prevProps) => {
      if (!autoUpdate) {
        handleDataFetch_3({ target: { value: dataValue_3 } });
      }
    },
    [
      startDate,
      endDate,
      intervalSelection,
      selectedSpnNumbers_3[0],
      selectedSpnNumbers_3[1],
    ]
  );

  const handleCheckboxChangeLeft = (option, chartNum) => {
    const { description, spnNumber, umdisplay } = option;

    if (chartNum === 1) {
      if (selectedTypes.includes(description)) {
        return;
      }
      setSelectedTypes((prev) => {
        // Create a new array with the updated value at index 0
        const updatedSelectedTypes = [...prev];
        updatedSelectedTypes[0] = description;
        return updatedSelectedTypes;
      });

      setDataValue((prev) => {
        // Create a new array with the updated value at index 0
        const updatedSpnNumber = [...prev];
        updatedSpnNumber[0] = spnNumber;
        return updatedSpnNumber;
      });

      setSelectedSpnNumbers((prev) => {
        // Create a new array with the updated value at index 0
        const updatedSpnNumber = [...prev];
        updatedSpnNumber[0] = spnNumber;
        return updatedSpnNumber;
      });

      setUmDisplay((prev) => {
        // Create a new array with the updated value at index 0
        const updatedUmDisplay = [...prev];
        updatedUmDisplay[0] = umdisplay;
        return updatedUmDisplay;
      });

      setSubCheckboxStates((prev) => {
        const newStates = { ...prev };

        if (isCheckedColor(newStates, description)) {
          return newStates;
        } else {
          if (redChecked) {
            newStates[redChecked] = { checked: false, color: "" };
            setRedChecked(null);
          }
          newStates[description] = { checked: true, color: "red" };
          setRedChecked(description);
        }

        return newStates;
      });
    } else if (chartNum === 2) {
      if (selectedTypes_2.includes(description)) {
        return;
      }
      setSelectedTypes_2((prev) => {
        // Create a new array with the updated value at index 0
        const updatedSelectedTypes = [...prev];
        updatedSelectedTypes[0] = description;
        return updatedSelectedTypes;
      });

      setDataValue_2((prev) => {
        // Create a new array with the updated value at index 0
        const updatedSpnNumber = [...prev];
        updatedSpnNumber[0] = spnNumber;
        return updatedSpnNumber;
      });

      setSelectedSpnNumbers_2((prev) => {
        // Create a new array with the updated value at index 0
        const updatedSpnNumber = [...prev];
        updatedSpnNumber[0] = spnNumber;
        return updatedSpnNumber;
      });

      setUmDisplay_2((prev) => {
        // Create a new array with the updated value at index 0
        const updatedUmDisplay = [...prev];
        updatedUmDisplay[0] = umdisplay;
        return updatedUmDisplay;
      });

      setSubCheckboxStates_2((prev) => {
        const newStates = { ...prev };

        if (isCheckedColor(newStates, description)) {
          return newStates;
        } else {
          if (redChecked_2) {
            newStates[redChecked_2] = { checked: false, color: "" };
            setRedChecked_2(null);
          }
          newStates[description] = { checked: true, color: "red" };
          setRedChecked_2(description);
        }

        return newStates;
      });
    } else if (chartNum === 3) {
      if (selectedTypes_3.includes(description)) {
        return;
      }
      setSelectedTypes_3((prev) => {
        // Create a new array with the updated value at index 0
        const updatedSelectedTypes = [...prev];
        updatedSelectedTypes[0] = description;
        return updatedSelectedTypes;
      });

      setDataValue_3((prev) => {
        // Create a new array with the updated value at index 0
        const updatedSpnNumber = [...prev];
        updatedSpnNumber[0] = spnNumber;
        return updatedSpnNumber;
      });

      setSelectedSpnNumbers_3((prev) => {
        // Create a new array with the updated value at index 0
        const updatedSpnNumber = [...prev];
        updatedSpnNumber[0] = spnNumber;
        return updatedSpnNumber;
      });

      setUmDisplay_3((prev) => {
        // Create a new array with the updated value at index 0
        const updatedUmDisplay = [...prev];
        updatedUmDisplay[0] = umdisplay;
        return updatedUmDisplay;
      });

      setSubCheckboxStates_3((prev) => {
        const newStates = { ...prev };

        if (isCheckedColor(newStates, description)) {
          return newStates;
        } else {
          if (redChecked_3) {
            newStates[redChecked_3] = { checked: false, color: "" };
            setRedChecked_3(null);
          }
          newStates[description] = { checked: true, color: "red" };
          setRedChecked_3(description);
        }

        return newStates;
      });
    }
  };
  const isCheckedColor = (obj, description) => {
    for (const key in obj) {
      if (obj[key].color === "red" && key === description) {
        return true;
      }
    }
    return false;
  };

  const isCheckedColor2 = (obj, description) => {
    for (const key in obj) {
      if (obj[key].color === "blue" && key === description) {
        return true;
      }
    }
    return false;
  };

  //This is the change event for the sub checkboxes.
  //This handles all three sets of sub checkboxes, determined by the chartNum
  const handleCheckboxChangeRight = (option, chartNum) => {
    const { description, spnNumber, umdisplay } = option;

    //if we select an item already checked then do nothing
    if (chartNum === 1) {
      if (selectedTypes.includes(description)) {
        return;
      }

      setSelectedTypes((prev) => {
        // Create a new array with the updated value at index 1
        const updatedSelectedTypes = [...prev];

        if (updatedSelectedTypes[1] === description) {
          updatedSelectedTypes[1] = "";
          setShowRightAxis(false);
        } else {
          updatedSelectedTypes[1] = description;
          setShowRightAxis(true);
        }

        return updatedSelectedTypes;
      });

      setDataValue((prev) => {
        // Create a new array with the updated value at index 1
        const updatedSpnNumber = [...prev];
        updatedSpnNumber[1] = spnNumber;
        return updatedSpnNumber;
      });

      setSelectedSpnNumbers((prev) => {
        // Create a new array with the updated value at index 1
        const updatedSpnNumber = [...prev];
        updatedSpnNumber[1] = spnNumber;
        return updatedSpnNumber;
      });

      setUmDisplay((prev) => {
        // Create a new array with the updated value at index 1
        const updatedUmDisplay = [...prev];
        updatedUmDisplay[1] = umdisplay;
        return updatedUmDisplay;
      });

      // setSubCheckboxStates((prev) => {
      //   const newStates = { ...prev };

      //   if (
      //     blueChecked === description &&
      //     newStates[blueChecked].checked === true
      //   ) {
      //     newStates[blueChecked] = { checked: false, color: "" };
      //     setBlueChecked(null);
      //   } else {
      //     if (blueChecked) {
      //       newStates[blueChecked] = { checked: false, color: "" };
      //     }
      //     newStates[description] = { checked: true, color: "blue" };
      //     setBlueChecked(description);
      //   }

      //   return newStates;
      // });
      setSubCheckboxStates((prev) => {
        const newStates = { ...prev };

        if (isCheckedColor2(newStates, description)) {
          return newStates;
        } else {
          if (blueChecked) {
            newStates[blueChecked] = { checked: false, color: "" };
            setBlueChecked(null);
          }
          newStates[description] = { checked: true, color: "blue" };
          setBlueChecked(description);
        }

        return newStates;
      });
    } else if (chartNum === 2) {
      if (selectedTypes_2.includes(description)) {
        return;
      }

      setSelectedTypes_2((prev) => {
        // Create a new array with the updated value at index 1
        const updatedSelectedTypes = [...prev];

        if (updatedSelectedTypes[1] === description) {
          updatedSelectedTypes[1] = "";
          setShowRightAxis_2(false);
        } else {
          updatedSelectedTypes[1] = description;
          setShowRightAxis_2(true);
        }

        return updatedSelectedTypes;
      });

      setDataValue_2((prev) => {
        // Create a new array with the updated value at index 1
        const updatedSpnNumber = [...prev];
        updatedSpnNumber[1] = spnNumber;
        return updatedSpnNumber;
      });

      setSelectedSpnNumbers_2((prev) => {
        // Create a new array with the updated value at index 1
        const updatedSpnNumber = [...prev];
        updatedSpnNumber[1] = spnNumber;
        return updatedSpnNumber;
      });

      setUmDisplay_2((prev) => {
        // Create a new array with the updated value at index 1
        const updatedUmDisplay = [...prev];
        updatedUmDisplay[1] = umdisplay;
        return updatedUmDisplay;
      });

      setSubCheckboxStates_2((prev) => {
        const newStates = { ...prev };

        if (isCheckedColor2(newStates, description)) {
          return newStates;
        } else {
          if (blueChecked_2) {
            newStates[blueChecked_2] = { checked: false, color: "" };
            setBlueChecked_2(null);
          }
          newStates[description] = { checked: true, color: "blue" };
          setBlueChecked_2(description);
        }

        return newStates;
      });
      // setSubCheckboxStates_2((prev) => {
      //   const newStates = { ...prev };

      //   if (
      //     blueChecked_2 === description &&
      //     newStates[blueChecked_2].checked === true
      //   ) {
      //     newStates[blueChecked_2] = { checked: false, color: "" };
      //     setBlueChecked_2(null);
      //   } else {
      //     if (blueChecked_2) {
      //       newStates[blueChecked_2] = { checked: false, color: "" };
      //     }
      //     newStates[description] = { checked: true, color: "blue" };
      //     setBlueChecked_2(description);
      //   }

      //   return newStates;
      // });
    } else if (chartNum === 3) {
      if (selectedTypes_3.includes(description)) {
        return;
      }

      setSelectedTypes_3((prev) => {
        // Create a new array with the updated value at index 1
        const updatedSelectedTypes = [...prev];

        if (updatedSelectedTypes[1] === description) {
          updatedSelectedTypes[1] = "";
          setShowRightAxis_3(false);
        } else {
          updatedSelectedTypes[1] = description;
          setShowRightAxis_3(true);
        }

        return updatedSelectedTypes;
      });

      setDataValue_3((prev) => {
        // Create a new array with the updated value at index 1
        const updatedSpnNumber = [...prev];
        updatedSpnNumber[1] = spnNumber;
        return updatedSpnNumber;
      });

      setSelectedSpnNumbers_3((prev) => {
        // Create a new array with the updated value at index 1
        const updatedSpnNumber = [...prev];
        updatedSpnNumber[1] = spnNumber;
        return updatedSpnNumber;
      });

      setUmDisplay_3((prev) => {
        // Create a new array with the updated value at index 1
        const updatedUmDisplay = [...prev];
        updatedUmDisplay[1] = umdisplay;
        return updatedUmDisplay;
      });
      setSubCheckboxStates_3((prev) => {
        const newStates = { ...prev };

        if (isCheckedColor2(newStates, description)) {
          return newStates;
        } else {
          if (blueChecked_3) {
            newStates[blueChecked_3] = { checked: false, color: "" };
            setBlueChecked_3(null);
          }
          newStates[description] = { checked: true, color: "blue" };
          setBlueChecked_3(description);
        }

        return newStates;
      });
      // setSubCheckboxStates_3((prev) => {
      //   const newStates = { ...prev };

      //   if (
      //     blueChecked_3 === description &&
      //     newStates[blueChecked_3].checked === true
      //   ) {
      //     newStates[blueChecked_3] = { checked: false, color: "" };
      //     setBlueChecked_3(null);
      //   } else {
      //     if (blueChecked_3) {
      //       newStates[blueChecked_3] = { checked: false, color: "" };
      //     }
      //     newStates[description] = { checked: true, color: "blue" };
      //     setBlueChecked_3(description);
      //   }

      //   return newStates;
      // });
    }
  };

  const getUmDisplayBySpnNumber = (spnNumber) => {
    const found = spnDescriptionsAry.find(
      (item) => item.spnNumber === spnNumber
    );
    return found ? found.umdisplay : null;
  };

  const clickHideShowDataTypes = (num) => {
    if (num === 1) {
      setIsExpanded(!isExpanded);
      setIsExpanded_2(false);
      setIsExpanded_3(false);
    } else if (num === 2) {
      setIsExpanded(false);
      setIsExpanded_2(!isExpanded_2);
      setIsExpanded_3(false);
    } else if (num === 3) {
      setIsExpanded(false);
      setIsExpanded_2(false);
      setIsExpanded_3(!isExpanded_3);
    }
  };

  function CustomChart(
    domain,
    chartData,
    umDisplay,
    chartName,
    showLeftAxis,
    showRightAxis,
    chartNum
  ) {
    const styles = getStyles();

    return (
      <VictoryChart
        height={335}
        padding={{ top: 50, bottom: 85, left: 50, right: 50 }}
        key={chartNum}
      >
        <VictoryAxis
          standalone={false}
          tickFormat={(x) => formatXAxisTick(x, chartNum)}
          style={styles.axisYears}
        />
        {chartData[0].length > 0 && showLeftAxis ? (
          <>
            <VictoryLabel
              x={25}
              y={20}
              style={styles.labelOne}
              text={chartName[0]}
            />
            <VictoryAxis
              dependentAxis
              domain={domain[0]}
              offsetX={50}
              orientation="left"
              standalone={false}
              style={styles.axisOne}
              label={umDisplay[0]}
            />

            <VictoryLine
              data={chartData[0]}
              standalone={false}
              style={styles.lineOne}
            />
          </>
        ) : (
          <VictoryLabel
            text={chartName[0] ? "No Data Available for " + chartName[0] : ""}
            x={25}
            y={20}
            style={styles.labelOne}
          />
        )}

        {chartData[1].length > 0 && showRightAxis ? (
          <>
            <VictoryLabel
              x={420}
              y={20}
              style={styles.labelTwo}
              text={chartName[1]}
            />
            <VictoryAxis
              dependentAxis
              domain={domain[1]}
              orientation="right"
              standalone={false}
              style={styles.axisTwo}
              label={umDisplay[1]}
            />

            <VictoryLine
              data={chartData[1]}
              standalone={false}
              style={styles.lineTwo}
            />
          </>
        ) : (
          <VictoryLabel
            //    text={chartName[1] ? "No Data Available for " + chartName[1] : ""}
            x={420}
            y={20}
            style={styles.labelTwo}
          />
        )}
      </VictoryChart>
    );
  }

  function getStyles() {
    const GREEN_COLOR = "#168dc9";
    const BLUE_COLOR = "#87CB15";

    return {
      parent: {
        background: "transparent",
        boxSizing: "border-box",
        display: "inline",
        padding: 5,
      },
      title: {
        textAnchor: "start",
        verticalAnchor: "end",
        fill: "#000000",
        fontSize: "16px",
        fontWeight: "bold",
      },
      labelNumber: {
        textAnchor: "middle",
        fill: "#ffffff",
        fontSize: "14px",
      },

      axisYears: {
        axis: { stroke: "yellow", strokeWidth: 1 },

        tickLabels: {
          angle: -40, // Angle should be negative to tilt labels up to the right
          fontSize: "10px",
          //  marginTop:-10,
          textAnchor: "end", // Adjust text anchor to 'end' if labels are angled
          fill: "white",
        },
      },

      // DATA SET ONE
      axisOne: {
        grid: {
          stroke: "#ffffff",
          strokeWidth: 1,
        },
        axis: { stroke: BLUE_COLOR, strokeWidth: 0 },
        ticks: { strokeWidth: 0 },
        tickLabels: {
          fill: "#ffffff",
          fontSize: 9,
        },
        axisLabel: {
          fill: "white",
          fontSize: 11,
          padding: 39,
        },
      },
      labelOne: {
        fill: BLUE_COLOR,
        fontSize: 12,
        fontStyle: "italic",
      },
      lineOne: {
        data: { stroke: BLUE_COLOR, strokeWidth: 2 },
      },
      axisOneCustomLabel: {
        fill: BLUE_COLOR,
        fontWeight: 300,
        fontSize: 21,
      },

      // DATA SET TWO
      axisTwo: {
        axis: { stroke: GREEN_COLOR, strokeWidth: 0 },
        tickLabels: {
          fill: "#ffffff",
          fontSize: 9,
        },
        axisLabel: {
          fill: "white",
          fontSize: 11,
          padding: 39,
        },
      },
      labelTwo: {
        textAnchor: "end",
        fill: GREEN_COLOR,
        fontSize: 12,
        fontStyle: "italic",
      },
      lineTwo: {
        data: { stroke: GREEN_COLOR, strokeWidth: 2 },
      },
    };
  }

  return (
    <>
      <div>
        {/* DateTime Range Selection UI with vertical flow */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            marginLeft: "10px",
            marginBottom: "10px",
          }}
        >
          <div
            style={{ display: "flex", flexDirection: "column", width: "50%" }}
          >
            <label style={{ marginBottom: "5px" }}>START TIME:</label>
            <DatePicker
              selected={startDate}
              onChange={(date) => handleStartDateChange(date)}
              showTimeSelect
              dateFormat="Pp"
              style={{
                display: "block",
                padding: "3px",
                marginBottom: "20px",
                width: "100%",
              }}
            />
            <label style={{ marginTop: "10px", marginBottom: "5px" }}>
              END TIME:
            </label>
            <DatePicker
              selected={endDate}
              onChange={(date) => handleEndDateChange(date)}
              showTimeSelect
              dateFormat="Pp"
              style={{ display: "block", padding: "4px", width: "100%" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "20px",
              width: "50%",
            }}
          >
            <label style={{ marginBottom: "5px" }}>INTERVAL TIME:</label>
            <select
              value={intervalSelection}
              ref={intervalSelectionRef}
              onChange={(e) => setIntervalSelection(e.target.value)}
              style={{
                display: "block",
                padding: "3px",
                marginBottom: "20px",
                width: "40%",
              }}
            >
              <option value="10">10 sec</option>
              <option value="20">20 sec</option>
              <option value="30">30 sec</option>
              <option value="60">1 min</option>
              <option value="90">1.5 min</option>
              <option value="120">2 min</option>
              <option value="900">15 min</option>
              <option value="1800">30 min</option>
              <option value="3600">1 hr </option>
              <option value="7200">2 hr</option>
            </select>
          </div>
        </div>

        {/* First chart */}
        <div style={{ paddingBottom: "50px" }}>
          <button
            className="datatypes-btn"
            onClick={() => clickHideShowDataTypes(1)}
            style={{ display: "block", marginBottom: "10px" }}
          >
            {isExpanded ? "HIDE DATA TYPES" : "SHOW DATA TYPES"}
          </button>

          {isExpanded ? (
            <>
              <div style={{ float: "left", marginTop: 10 }}>
                <Checkbox
                  // name=""
                  classX={"checkboxA red"}
                  number={1}
                  label={"left side"}
                  checked={chartLeftAxis}
                  onChange={() => handleChartAxisCheckboxChange("left", 1)}
                />
              </div>
              <div style={{ float: "left", marginTop: 10 }}>
                <Checkbox
                  //  name=""
                  classX={"checkboxA blue"}
                  number={2}
                  label={"right side"}
                  checked={chartRightAxis}
                  onChange={() => handleChartAxisCheckboxChange("right", 1)}
                />
              </div>
            </>
          ) : null}
        </div>
        {isExpanded && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: "5px 2px",
              marginTop: "10px",
              marginBottom: "20px",
              marginLeft: "10px",
              float: "left",
            }}
          >
            {optionsData.map((option, i) => (
              <Checkbox
                key={i + option.spnNumber}
                classX={`checkboxA ${
                  subCheckboxStates[option.description]?.color || ""
                }`}
                number={option.spnNumber}
                label={option.description}
                checked={
                  subCheckboxStates[option.description]?.checked || false
                }
                onChange={
                  chartRightAxis
                    ? () => handleCheckboxChangeRight(option, 1)
                    : () => handleCheckboxChangeLeft(option, 1)
                }
              />
            ))}
          </div>
        )}
        {/* Chart 1*/}
        {CustomChart(
          domain,
          chartData,
          umDisplay,
          chartName,
          showLeftAxis,
          showRightAxis,
          1
        )}
      </div>

      {/* Second chart */}
      {optionsData_2.length > 0 ? (
        <div>
          <div style={{ paddingBottom: "50px" }}>
            <button
              className="datatypes-btn"
              onClick={() => clickHideShowDataTypes(2)}
              style={{ display: "block", marginBottom: "10px" }}
            >
              {isExpanded_2 ? "HIDE DATA TYPES" : "SHOW DATA TYPES"}
            </button>
            {isExpanded_2 ? (
              <>
                <div style={{ float: "left", marginTop: 10 }}>
                  <Checkbox
                    // name=""
                    classX={"checkboxA red"}
                    number={3}
                    label={"left side"}
                    checked={chartLeftAxis_2}
                    onChange={() => handleChartAxisCheckboxChange("left", 2)}
                  />
                </div>
                <div style={{ float: "left", marginTop: 10 }}>
                  <Checkbox
                    // name=""
                    classX={"checkboxA blue"}
                    number={4}
                    label={"right side"}
                    checked={chartRightAxis_2}
                    onChange={() => handleChartAxisCheckboxChange("right", 2)}
                  />
                </div>
              </>
            ) : null}
          </div>

          {isExpanded_2 && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                gap: "5px 2px",
                marginTop: "10px",
                marginBottom: "20px",
                marginLeft: "10px",
              }}
            >
              {optionsData_2.map((option) => (
                <Checkbox
                  key={option.spnNumber}
                  classX={`checkboxA ${
                    subCheckboxStates_2[option.description]?.color || ""
                  }`}
                  number={option.spnNumber}
                  label={option.description}
                  checked={
                    subCheckboxStates_2[option.description]?.checked || false
                  }
                  onChange={
                    chartRightAxis_2
                      ? () => handleCheckboxChangeRight(option, 2)
                      : () => handleCheckboxChangeLeft(option, 2)
                  }
                />
              ))}
            </div>
          )}
          {/* Chart 2*/}
          {CustomChart(
            domain_2,
            chartData_2,
            umDisplay_2,
            chartName_2,
            showLeftAxis_2,
            showRightAxis_2,
            2
          )}
        </div>
      ) : null}

      {/* Third chart */}
      {optionsData_3.length > 0 ? (
        <div>
          <div style={{ paddingBottom: "50px" }}>
            <button
              className="datatypes-btn"
              onClick={() => clickHideShowDataTypes(3)}
              style={{ display: "block", marginBottom: "10px" }}
            >
              {isExpanded_3 ? "HIDE DATA TYPES" : "SHOW DATA TYPES"}
            </button>
            {isExpanded_3 ? (
              <>
                <div style={{ float: "left", marginTop: 10 }}>
                  <Checkbox
                    //  name=""
                    classX={"checkboxA red"}
                    number={5}
                    label={"left side"}
                    checked={chartLeftAxis_3}
                    onChange={() => handleChartAxisCheckboxChange("left", 3)}
                  />
                </div>
                <div style={{ float: "left", marginTop: 10 }}>
                  <Checkbox
                    //  name=""
                    classX={"checkboxA blue"}
                    number={6}
                    label={"right side"}
                    checked={chartRightAxis_3}
                    onChange={() => handleChartAxisCheckboxChange("right", 3)}
                  />
                </div>
              </>
            ) : null}
          </div>

          {isExpanded_3 && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                gap: "5px 2px",
                marginTop: "10px",
                marginBottom: "20px",
                marginLeft: "10px",
              }}
            >
              {optionsData_3.map((option) => (
                <Checkbox
                  key={option.spnNumber}
                  // name=""
                  classX={`checkboxA ${
                    subCheckboxStates_3[option.description]?.color || ""
                  }`}
                  number={option.spnNumber}
                  label={option.description}
                  checked={
                    subCheckboxStates_3[option.description]?.checked || false
                  }
                  onChange={
                    chartRightAxis_3
                      ? () => handleCheckboxChangeRight(option, 3)
                      : () => handleCheckboxChangeLeft(option, 3)
                  }
                />
              ))}
            </div>
          )}
          {/* Chart 3*/}
          {CustomChart(
            domain_3,
            chartData_3,
            umDisplay_3,
            chartName_3,
            showLeftAxis_3,
            showRightAxis_3,
            3
          )}
        </div>
      ) : null}
    </>
  );
};

export default ChartView;
