import React, { useState, useEffect, useRef, useMemo } from "react";
import { Grid, Col, Row } from "react-bootstrap";
import DeviceList from "components/Devices/DeviceList.jsx";
import SelectedDevice from "components/Devices/SelectedDevice.jsx";

import SearchBar from "components/SearchBar/SearchBar.jsx";
import Card from "components/Card/Card.jsx";
import MenuButton from "components/MenuButton/MenuButton.jsx";

import BrowserHistory from "models/BrowserHistory";
import DeviceMap from "components/Devices/DeviceMap";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedDevice,
  setDisplayedDevices,
  setSearched,
  setZoom,
  setCenter,
  setDeviceListUpdate,
  setDeviceUpdate,
} from "../redux/dataSlice";

const Dashboard = ({ allDevices }) => {
  //redux states
  const latLng = { lat: 33.6, lng: -96.5 };
  const dispatch = useDispatch();
  const searched = useSelector((state) => state.data.searched);
  const selectedDevice = useSelector((state) => state.data.selectedDevice);
  const displayedDevices = useSelector((state) => state.data.displayedDevices);
  const filters = useSelector((state) => state.data.filters);

  const currentPage = useSelector(
    (state) => state.currentPageSlice.currentPage
  );
  const zoom = useSelector((state) => state.data.zoom);
  const center = useSelector((state) => state.data.center);

  const [searchTerm, setSearchTerm] = useState("");
  const handleDeviceSelection = (device) => {
    dispatch(setSelectedDevice([device]));
    dispatch(setSearched(false));
    dispatch(setDeviceListUpdate(false));
    dispatch(setDeviceUpdate(true));

    BrowserHistory.replace(`/admin/dashboard/${device.id}`);
  };

  const handleEquipmentSearch = (query) => {
    let devices = getFilters(filters);

    const filteredData = devices.filter((item) => {
      return (
        // if in the future, if necessary we can ssearch by other items in the object
        // item.description.toLowerCase().includes(query.toLowerCase()) ||

        item.name.toLowerCase().includes(query.toLowerCase()) //tgs - chgd item.data_name to item.name
      );
    });

    setSearchTerm(query);
    dispatch(setCenter(latLng));
    dispatch(setSearched(true));
    dispatch(setDisplayedDevices(filteredData));
  };

  const setDevicesFromSelectedCluster = (devices) => {
    dispatch(setDisplayedDevices(devices));
  };

  const handleClearSearch = () => {
    dispatch(setSearched(false));
    setSearchTerm("");
    dispatch(setSelectedDevice([]));
    dispatch(setDisplayedDevices(getFilters(filters)));
    dispatch(setZoom(5));
    dispatch(setCenter(latLng));
  };

  const setZoomLevel = (z) => {
    dispatch(setZoom(z));
  };

  const setCenterLatLong = (ll) => {
    dispatch(setCenter(ll));
  };

  const resetMap = () => {
    handleClearSearch();
  };

  const getFilters = (filtersX) => {
    const dvs = allDevices;
    const ftr = filtersX;

    function filterDevices(ftr, dvs) {
      // Define the mapping between ftr keys and dvs properties
      const isWithinLast7Days = (dateString) => {
        // console.log("!!!!!DATE " + dateString);
        if (!dateString) return false;
        const date = new Date(dateString);
        const sevenDaysAgo = new Date();
        sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
        return date >= sevenDaysAgo;
      };
      const keyMap = {
        online: { property: "status", value: "Online" },
        connected: { property: "status", value: "Connected" },
        needsAttention: { property: "status", value: "Disconnected" },
        offline: { property: "status", value: "Offline" },
        recent: { property: "lastContact._d", value: isWithinLast7Days },
        // add other mappings here
      };

      // Get required statuses
      const requiredStatuses = Object.keys(ftr)
        .filter((key) => ftr[key] && keyMap[key]?.property === "status")
        .map((key) => keyMap[key].value);

      return dvs.filter((taco) => {
        // Check status if there are required statuses
        if (
          requiredStatuses.length > 0 &&
          !requiredStatuses.includes(taco.status)
        ) {
          return false;
        }

        if (ftr["recent"] && keyMap["recent"]) {
          const { property, value } = keyMap["recent"];
          // Extracting the nested property value
          const propertyPath = property.split(".");
          let dateValue = taco;
          for (const prop of propertyPath) {
            if (dateValue[prop] !== null) {
              dateValue = dateValue[prop];
            } else {
              // Property is null or not found, set dateValue to null and exit loop
              dateValue = null;
              break;
            }
          }
          // Check if dateValue is not null and the date is within the required range
          if (dateValue !== null && value(dateValue)) {
            return true; // Include in the result
          } else {
            return false; // Exclude from the result
          }
        }
        return true;
      });
    }

    const filteredDevices = filterDevices(ftr, dvs);
    return filteredDevices;
  };
  const setFilterOptions = (fil) => {
    const filteredDevices = getFilters(fil);
    dispatch(setDisplayedDevices(filteredDevices));
  };

  const showDevices = useMemo(() => {
    let devices = getFilters(filters);

    if (selectedDevice.length > 0) {
      devices = selectedDevice;
    } else if (displayedDevices && displayedDevices.length > 0) {
      devices = displayedDevices;
    } else if (
      (displayedDevices.length === 0 || selectedDevice.length === 0) &&
      searched
    ) {
      devices = [];
    }
    return devices;
  }, [searched, selectedDevice, displayedDevices, allDevices, searchTerm]);

  useEffect(() => {}, []);

  useEffect(() => {
    if (getFilters(filters) && getFilters(filters).length > 0) {
      // Code to run only once when allDevices has data
      const path = BrowserHistory.location.pathname;
      if (path !== "/admin/dashboard") {
        const segments = path.split("/");
        const targetSegment = segments[segments.length - 1]; // Get the last element of the array
        const device = getFilters(filters).find(
          (obj) => obj.id === targetSegment
        );
        // console.log(device);
        if (device) {
          handleDeviceSelection(device);
        }
      }
    }
  }, [allDevices]);

  if (allDevices.length === 0) {
    return (
      <Grid fluid>
        <Row>
          <Col lg={12} style={{ height: 480 }}></Col>
        </Row>
        <Row>
          <Col lg={12} style={{ height: window.innerHeight - 480 }}></Col>
        </Row>
      </Grid>
    );
  }

  return (
    <div className="main-content">
      {currentPage === "vehicleView" && showDevices.length === 1 ? (
        <SelectedDevice />
      ) : (
        <Grid fluid>
          <Row>
            <Col lg={12} style={{ height: 480 }}>
              <DeviceMap
                // key={mapKey}
                zoomLevel={zoom}
                center={center}
                devices={showDevices}
                setZoomLevel={setZoomLevel}
                setCenter={setCenterLatLong}
                setDevices={setDevicesFromSelectedCluster}
                onDeviceSelect={handleDeviceSelection}
              />
            </Col>
          </Row>

          <Row>
            <div />
          </Row>
          <Row style={{ height: 50 }}>
            <MenuButton getFilters={setFilterOptions} />

            <SearchBar
              onEquipmentSearch={handleEquipmentSearch}
              onClearSearch={handleClearSearch}
              searchTerm={searchTerm}
            />

            <div className="dashboard-reset-btn">
              <button onClick={resetMap}>{"RESET MAP"}</button>
            </div>
          </Row>

          <Row className="pt-2">
            <Col lg={12}>
              <Card
                content={
                  <>
                    <div>
                      {selectedDevice.length === 0 &&
                      getFilters(filters).length === 0 ? (
                        <span>
                          <small style={{ color: "white" }}>Loading</small>
                        </span>
                      ) : null}
                    </div>
                    {showDevices.length === 0 && searched ? (
                      <span>
                        <small style={{ color: "white" }}>No Data Found</small>
                      </span>
                    ) : (
                      <DeviceList
                        devices={showDevices}
                        onDeviceSelect={handleDeviceSelection}
                      />
                    )}
                  </>
                }
              />
            </Col>
          </Row>
        </Grid>
      )}
    </div>
  );
};

export default Dashboard;
