// currentPageSlice.js
import { createSlice } from '@reduxjs/toolkit';

export const currentPageSlice = createSlice({
    name: 'currentPageSlice',
    initialState: {
        currentPage: '',
    },
    reducers: {
        changePage: (state, action) => {
            state.currentPage = action.payload;
          },
    },
});
export const { changePage } = currentPageSlice.actions;
export default currentPageSlice.reducer;
