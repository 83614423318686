import moment from "moment";
import imgPump from "assets/img/ex-pump.jpg";
import mustImg from "assets/img/mustang-img_90x60.jpg"; //tgs
import BrowserHistory from "./BrowserHistory.js";

let apiSelf = {};
let deviceSelf;
let userSelf;
let appSelf = {};
let locationSelf;
function token_is_valid(t) {
  //eventually, try validate exp
  return !!t;
}

function unit_convert(obj) {
  //obj has a val and a units
  if (obj.units == "kPa") {
    obj.val = obj.val * 0.145038;
    obj.units = "PSI";
  } else if (obj.units == "°C") {
    //ohh how I hate that they include the degree symbol. I don't blame them, but I don't like it either.
    obj.val = obj.val * 1.8 + 32;
    obj.units = "°F";
  } else if (obj.units == "l/h") {
    obj.val = obj.val * 0.264172;
    obj.units = "g/h";
  }
  return obj;
}

function API() {
  //TODO: store token, fetch token, renew if nearing expiry, check session and localStorage
  //TODO: handle no token, 401, as redirect to /auth/login
  apiSelf.token = localStorage.getItem("AUTH_TOKEN");
  apiSelf.logged_in = token_is_valid(apiSelf.token);

  apiSelf.on_401 = function () {
    userUpdate({ id: 0 });

    // appSelf.user.update({ id: 0 });
    if (BrowserHistory.location.pathname.indexOf("/auth/login") < 0) {
      BrowserHistory.push("/auth/login");
    }
  };
  apiSelf.on_login = function () {
    if (apiSelf.logged_in) {
      apiSelf.fetch_user().then((u) => {
        userUpdate(u);
        appSelf.updateId += 1;

        // fireChange();

        // appSelf.user.update(u); fireChange();
      }, console.error);
    }
  };

  apiSelf.do_gql_request = function do_gql_request(query, vars) {
    return fetch("/api/v1/graphql", {
      method: "POST",
      headers: {
        Authorization: apiSelf.token ? "Bearer " + apiSelf.token : "UNAUTH",
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: query,
        variables: vars,
      }),
    }).then(function (resp) {
      if (resp.status == 401) {
        apiSelf.logged_in = false;
        apiSelf.token = null;
        apiSelf.on_401();
        return Promise.reject("UNAUTH");
      } else {
        return resp;
      }
    });
  };

  apiSelf.fetch_dashboard = async function fetch_dashboard() {
    return apiSelf
      .do_gql_request(
        `
      query {
        activeUser {
          id name
          customer { id name }
        }
        vehicles {
          id name kind description lastContact status
          gps {
            at lat lng
            near { city state}
          }
          lastTriconTime
          lastTricon(id: [206, 0, 6, 25, 22, 15, 1, 136, 20, 31]) {
            idx
            text
            nice
          }
          lastJ1939(id: ["190_0_255", "92_0_255", "110_0_255", "94_0_255", "100_0_255", "183_0_255"]) {
            val
            spn { spn slot { unit } }
          }
          lastJ1939Time
        }
      }
     `,
        {}
      )
      .then(function (resp) {
        if (resp.status === 200) {
          return resp.json().then((raw_results) => {
            return {
              user: {
                id: raw_results.data.activeUser.id,
                name: raw_results.data.activeUser.name,
                company_id: raw_results.data.activeUser.customer.id,
                company_name: raw_results.data.activeUser.customer.name,
              },
              vehicles: raw_results.data.vehicles.map((v) => ({
                id: v.id,
                name: v.name,
                description: v.description,
                status: v.status,
                kind: v.kind,
                lastContact: v.lastContact ? moment.utc(v.lastContact) : null,
                triconTime: v.lastTriconTime
                  ? moment.utc(v.lastTriconTime)
                  : null,
                tricon: v.lastTricon.reduce((accum, val) => {
                  accum["tri_" + val.idx] = {
                    idx: val.idx,
                    text: val.text,
                    nice: val.nice,
                  };
                  return accum;
                }, {}),
                j1939Time: v.lastJ1939Time ? moment.utc(v.lastJ1939Time) : null,
                j1939: v.lastJ1939.reduce((accum, val) => {
                  //spn 190 => engine speed, 92 => engine load, 247 => hours, 110 => coolant tmp, 94 => fuel psi, 100 => oil psi, 183 => fuel rate, 168 => voltage
                  accum["spn_" + val.spn.spn] = unit_convert({
                    val: val.val,
                    units: val.spn.slot.unit,
                  });
                  return accum;
                }, {}),
                gps: v.gps
                  ? {
                      at: moment.utc(v.gps.at),
                      lat: v.gps.lat,
                      lng: v.gps.lng,
                      near: v.gps.near
                        ? v.gps.near.city + " " + v.gps.near.state
                        : "USA",
                    }
                  : null,
              })),
            };
          });
        } else if (resp.status === 401) {
          return Promise.reject("UNAUTH");
        } else {
          return Promise.reject("HTTP ERROR " + resp.status);
        }
      });
  };

  apiSelf.fetch_vehicle = function fetch_vehicle(id) {
    return apiSelf
      .do_gql_request(
        `
      query ($id: ID!) {
        vehicle(id: $id) {
          id name kind description lastContact
          gps {
            at lat lng
            near { city state}
          }
          lastTriconTime
          lastTricon(id: [], latestOnly: false) {
            idx
            text
            nice
            spec { rawSection niceSection shortName }
          }

          lastJ1939Time
          lastJ1939Msg {
            can0: canN(idx: 0) {
              hexId
              hexData
              j1939 {
                pgn { pgn name abbr }
                src { addr name }
                dst { addr name }
                spns(excludeDefaults: true) {
                  val
                  meaning
                  spn {
                    spn
                    name
                    slot { unit }
                  }
                }
              }
            }
          }
        }
      }
    `,
        { id: id }
      )
      .then(function (resp) {
        if (resp.status === 200) {
          return resp.json().then((raw_results) => {
            let v = raw_results.data.vehicle;
            return v
              ? {
                  id: v.id,
                  name: v.name,
                  kind: v.kind,
                  description: v.description,
                  lastContact: v.lastContact ? moment.utc(v.lastContact) : null,
                  triconTime: v.lastTriconTime
                    ? moment.utc(v.lastTriconTime)
                    : null,
                  tricon: v.lastTricon?.reduce((accum, val) => {
                    accum["tri_" + val.idx] = {
                      idx: val.idx,
                      text: val.text,
                      nice: val.nice,
                      section: val.spec.rawSection,
                      niceSection: val.spec.niceSection,
                      niceName: val.spec.shortName,
                    };
                    return accum;
                  }, {}),
                  j1939Time: v.lastJ1939Time
                    ? moment.utc(v.lastJ1939Time)
                    : null,
                  j1939Snapshot: v.lastJ1939Msg?.can0
                    ? v.lastJ1939Msg?.can0.map((msg) => ({
                        id: msg.hexId,
                        data: msg.hexData,
                        has_j1939: !!msg.j1939?.pgn,
                        pgn_id: msg.j1939?.pgn?.pgn,
                        pgn_abbr: msg.j1939?.pgn?.abbr,
                        pgn_name: msg.j1939?.pgn?.name,
                        src_addr: msg.j1939?.src?.addr,
                        src_name: msg.j1939?.src?.name,
                        dst_addr: msg.j1939?.dst?.addr,
                        dst_name: msg.j1939?.dst?.name,
                        spns:
                          msg.j1939?.spns?.map((spn) =>
                            unit_convert({
                              val: spn.val,
                              meaning: spn.meaning,
                              units: spn.spn.slot.unit,
                              name: spn.spn.name,
                              id: spn.spn.spn,
                            })
                          ) || [],
                      }))
                    : [],
                  gps: v.gps
                    ? {
                        at: moment.utc(v.gps.at),
                        lat: v.gps.lat,
                        lng: v.gps.lng,
                        near: v.gps.near
                          ? v.gps.near.city + " " + v.gps.near.state
                          : "USA",
                      }
                    : null,
                }
              : null;
          });
        } else if (resp.status === 401) {
          return Promise.reject("UNAUTH");
        } else {
          return Promise.reject("ERROR");
        }
      });
  };

  apiSelf.fetch_user = function fetch_user() {
    return apiSelf
      .do_gql_request(
        `
  query {
    activeUser {
      id name
      customer { id name }
      }
  }
    `,
        {}
      )
      .then(function (resp) {
        if (resp.status === 200) {
          return resp.json().then((raw_results) => {
            return {
              id: raw_results.data.activeUser.id,
              name: raw_results.data.activeUser.name,
              company_id: raw_results.data.activeUser.customer.id,
              company_name: raw_results.data.activeUser.customer.name,
            };
          });
        } else if (resp.status === 401) {
          return Promise.reject("UNAUTH");
        } else {
          return Promise.reject("ERROR");
        }
      });
  };

  apiSelf.try_change_password = function try_change_password(curr_pw, new_pw) {
    return fetch("/api/v1/change_password", {
      method: "POST",
      headers: {
        Authorization: apiSelf.token ? "Bearer " + apiSelf.token : "UNAUTH",
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        password: curr_pw,
        new_password: new_pw,
      }),
    }).then(function (resp) {
      if (resp.status === 200) {
        return resp.json().then((res) => {
          if (res.success) {
            return { success: true, message: "Password changed" };
          } else {
            return { success: false, message: res.message };
          }
        });
      } else {
        return { success: false, message: "Request Failed, unknown reason" };
      }
    });
  };
}

function format_num(n) {
  if (typeof n === "number") {
    if (Math.abs(n) < 0.001) {
      return n.toFixed(0);
    }
    if (Math.abs(n) < 1) {
      return n.toFixed(2);
    }
    if (Math.abs(n) < 100) {
      return n.toFixed(1);
    }
    return n.toFixed(0);
  } else {
    return n;
  }
}

function Location(proto) {
  locationSelf = this;
  function locationUpdate(proto) {
    if (proto) {
      locationSelf.valid = proto.lat && proto.lng;
      locationSelf.lat = proto.lat;
      locationSelf.lng = proto.lng;
      locationSelf.name = proto.near;
      locationSelf.at = proto.at;
    }
  }
  locationUpdate(proto);
}

function Device(app, proto) {
  deviceSelf = this;
  // deviceSelf.api = app.api;

  deviceSelf.history_labels = [];
  deviceSelf.history_rate = [];
  deviceSelf.history_gear = [];
  deviceSelf.history_rpm = [];
  deviceSelf.history_pressure = [];
  deviceSelf.history_total = [];
  deviceSelf.history_engoiltemp = [];
  deviceSelf.history_jrpm = [];
  deviceSelf.history_jload = [];
  deviceSelf.history_jfuelpsi = [];
  deviceSelf.history_jcoolant = [];
  deviceSelf.history_joil = [];
  deviceSelf.history_jfuelrate = [];

  deviceSelf.data_src = "pressure";
  deviceSelf.data_name = "Pressure";

  function do_history_update(triTim, tri, jTim, j1939) {
    let tim =
      jTim && triTim ? (jTim.isBefore(triTim) ? triTim : jTim) : jTim || triTim;
    deviceSelf.ref_time = tim;
    if (!tim) {
      return;
    }
    if (deviceSelf.history_labels.length == 0) {
      for (var i = 0; i < 15; i++) {
        deviceSelf.history_labels.push(tim.subtract(30 - 2 * i, "minutes"));
        deviceSelf.history_rate.push(tri?.tri_22?.nice ?? 0);
        deviceSelf.history_gear.push(tri?.tri_10?.nice ?? 0);
        deviceSelf.history_rpm.push(tri?.tri_0?.nice ?? 0);
        deviceSelf.history_pressure.push(tri?.tri_137?.nice ?? 0);
        deviceSelf.history_total.push(tri?.tri_136?.nice ?? 0);
        deviceSelf.history_engoiltemp.push(tri?.tri_1?.nice ?? 0);

        deviceSelf.history_jrpm.push(j1939?.spn_190?.val ?? 0);
        deviceSelf.history_jload.push(j1939?.spn_92?.val ?? 0);
        deviceSelf.history_jfuelpsi.push(j1939?.spn_94?.val ?? 0);
        deviceSelf.history_jcoolant.push(j1939?.spn_110?.val ?? 0);
        deviceSelf.history_joil.push(j1939?.spn_100?.val ?? 0);
        deviceSelf.history_jfuelrate.push(j1939?.spn_183?.val ?? 0);
      }
    }
    if (
      tim.diff(
        deviceSelf.history_labels[deviceSelf.history_labels.length - 1],
        "seconds"
      ) < 20
    ) {
      return;
    }
    deviceSelf.history_labels.push(tim);
    deviceSelf.history_rate.push(tri?.tri_22?.nice ?? 0);
    deviceSelf.history_gear.push(tri?.tri_10?.nice ?? 0);
    deviceSelf.history_rpm.push(tri?.tri_0?.nice ?? 0);
    deviceSelf.history_pressure.push(tri?.tri_137?.nice ?? 0);
    deviceSelf.history_total.push(tri?.tri_136?.nice ?? 0);
    deviceSelf.history_engoiltemp.push(tri?.tri_1?.nice ?? 0);

    deviceSelf.history_jrpm.push(j1939?.spn_190?.val ?? 0);
    deviceSelf.history_jload.push(j1939?.spn_92?.val ?? 0);
    deviceSelf.history_jfuelpsi.push(j1939?.spn_94?.val ?? 0);
    deviceSelf.history_jcoolant.push(j1939?.spn_110?.val ?? 0);
    deviceSelf.history_joil.push(j1939?.spn_100?.val ?? 0);
    deviceSelf.history_jfuelrate.push(j1939?.spn_183?.val ?? 0);

    while (deviceSelf.history_labels.length > 30) {
      deviceSelf.history_labels = deviceSelf.history_labels.splice(1);
      deviceSelf.history_rate = deviceSelf.history_rate.splice(1);
      deviceSelf.history_gear = deviceSelf.history_gear.splice(1);
      deviceSelf.history_rpm = deviceSelf.history_rpm.splice(1);
      deviceSelf.history_pressure = deviceSelf.history_pressure.splice(1);
      deviceSelf.history_total = deviceSelf.history_total.splice(1);
      deviceSelf.history_engoiltemp = deviceSelf.history_engoiltemp.splice(1);

      deviceSelf.history_jrpm = deviceSelf.history_jrpm.splice(1);
      deviceSelf.history_jload = deviceSelf.history_jload.splice(1);
      deviceSelf.history_jfuelpsi = deviceSelf.history_jfuelpsi.splice(1);
      deviceSelf.history_jcoolant = deviceSelf.history_jcoolant.splice(1);
      deviceSelf.history_joil = deviceSelf.history_joil.splice(1);
      deviceSelf.history_jfuelrate = deviceSelf.history_jfuelrate.splice(1);
    }
  }

  function deviceUpdate(proto) {
    /* just an array, has id, name, desc, last_contact, latest_msg, last_fix, last_lat, last_lon, created, updated */
    deviceSelf.id = proto.id;
    deviceSelf.name = proto.name;
    deviceSelf.type = proto.kind == "J1939RO" ? "J1939" : "Tricon";
    deviceSelf.isMustangRW = proto.kind == "MUSTANG_RW" ? true : false;
    //tgs - display pump img based on name value
    if (proto.name.toLowerCase().includes("mustang")) {
      // console.log('Found "mustang" in proto.name, case-insensitive check');
      deviceSelf.icon = (
        <div className="fa fa-lg">
          <img src={mustImg} />
        </div>
      );
    } else {
      deviceSelf.icon = (
        <div className="fa fa-lg">
          <img src={imgPump} />
        </div>
      );
    }

    deviceSelf.description = proto.description;
    deviceSelf.lastContact = proto.lastContact;
    deviceSelf.lastFix = proto.gps?.at;
    deviceSelf.oldLocation = deviceSelf.lastFix
      ? deviceSelf.lastFix.add(5, "minute").isBefore(deviceSelf.lastContact)
      : false; // technically lies
    deviceSelf.location = new Location(proto.gps);
    deviceSelf.status = proto.status;
    deviceSelf.isConnected =
      deviceSelf.status == "Online" || deviceSelf.status == "Connected";
    deviceSelf.triconTime = proto.triconTime;
    deviceSelf.prop_rate = proto.tricon?.tri_22?.text ?? "---";
    deviceSelf.prop_gear = proto.tricon?.tri_10?.text ?? "---";
    deviceSelf.prop_rpm = proto.tricon?.tri_0?.text ?? "---";
    deviceSelf.prop_loadPercent = proto.tricon?.tri_6?.text ?? "---"; // scottsr added for load %
    deviceSelf.prop_pumpSuctionPsi = proto.tricon?.tri_25?.text ?? "---"; // tgs added for suction psi
    deviceSelf.prop_dischargePsi = proto.tricon?.tri_15?.text ?? "---"; // tgs added for discharge psi
    deviceSelf.prop_pressure = proto.tricon?.tri_137?.text ?? "---";
    deviceSelf.prop_unitid = proto.tricon?.tri_206?.text ?? "---";
    deviceSelf.prop_total = proto.tricon?.tri_136?.text ?? "---";
    deviceSelf.prop_engoiltemp = proto.tricon?.tri_1?.text ?? "---";
    deviceSelf.j1939Time = proto.j1939Time;
    deviceSelf.prop_jrpm = format_num(proto.j1939?.spn_190?.val) ?? "---";
    deviceSelf.prop_jloadPercent =
      format_num(proto.j1939?.spn_92?.val) ?? "---"; // scottsr chg'd to match load %
    deviceSelf.prop_jfuelpsi = format_num(proto.j1939?.spn_94?.val) ?? "---";
    deviceSelf.prop_jcoolant = format_num(proto.j1939?.spn_110?.val) ?? "---";
    deviceSelf.prop_joil = format_num(proto.j1939?.spn_100?.val) ?? "---";
    deviceSelf.prop_jfuelrate = format_num(proto.j1939?.spn_183?.val) ?? "---";
    do_history_update(
      proto.triconTime,
      proto.tricon,
      proto.j1939Time,
      proto.j1939
    );
  }

  deviceSelf.id = proto.id;

  deviceUpdate(proto);

  deviceSelf.detailed_idx = 0;

  deviceSelf.sections = [];
}

function User(app) {
  userSelf = this;
  userSelf.name = "";
  userSelf.loggedIn = false;
  userSelf.id = -1;
}
function userUpdate(proto) {
  appSelf.name = proto.name;
  appSelf.loggedIn = proto.id > 0;
  appSelf.id = proto.id;
  appSelf.company_name = proto.company_name ?? "";
}

function fireChange() {
  appSelf.updateId += 1;
}

function extractReading(readings, index) {
  if (index < readings.length) {
    return {
      name: readings[index].name,
      value: readings[index].value,
    };
  }
  return { name: null, value: null };
}

function refresh_vehicles() {
  return new Promise(async (resolve, reject) => {
    appSelf.active_refresh += 1;
    let this_refresh = appSelf.active_refresh;
    appSelf.loading = true;
    apiSelf.fetch_dashboard().then(
      function (data) {
        if (this_refresh != appSelf.active_refresh) {
          return;
        }
        appSelf.loading = false;

        let vehicles = data.vehicles;

        //for each device in self.devices, if device not in vehicles, delete
        //if device in vehicles, update, and remove from vehicles
        //for remaining vehicles, add to list
        let existing_devices = appSelf.devices.filter(
          (d) => vehicles.filter((v) => v.id == d.id).length > 0
        );

        existing_devices.forEach((d) => {
          let v = vehicles.filter((v) => v.id == d.id)[0];
          // d.update(v);
          deviceUpdate(v);
        });
        let new_devices = vehicles
          .filter(
            (v) => existing_devices.filter((d) => d.id == v.id).length == 0
          )
          .map((v) => new Device(appSelf, v));

        appSelf.devices = existing_devices
          .concat(new_devices)
          .sort(function (v1, v2) {
            //ordering rules, for now:
            //order by status: online, connected, disconnected, offline, never connected, (other)
            //if online or connected, sort by id; otherwise sort by last contact
            const statii = ["online", "connected", "disconnected", "offline"];
            let s1 = statii.indexOf(v1.status.toLowerCase());
            if (s1 == -1) {
              s1 = statii.length;
            }
            let s2 = statii.indexOf(v2.status.toLowerCase());
            if (s2 == -1) {
              s2 = statii.length;
            }

            if (s1 != s2) {
              return s1 < s2 ? -1 : 1;
            } else if (s1 < 2) {
              return v1.id < v2.id ? -1 : 1;
            } else {
              if (v1.lastContact == v2.lastContact) return -1;
              if (v1.lastContact == null) return -1;
              if (v2.lastContact == null) return 1;
              return v1.lastContact.isBefore(v2.lastContact) ? 1 : -1;
            }
          });

        // appSelf.devices.forEach(d => {
        //   console.log(d)
        //   detailed_update(d)
        // });

        fireChange();
        resolve();
      },
      (e) => {
        appSelf.loading = false;
        console.error(e);
        fireChange();
        reject(e);
      }
    );
  });
}

//export the logout() in function to use on SideBar.jsx.jsx
export async function logout() {
  apiSelf.logged_in = false;
  apiSelf.token = "";
  localStorage.removeItem("AUTH_TOKEN");
  apiSelf.on_401();
}

//export the try login in function to use on LoginPage.jsx
export async function try_login(un, pw) {
  //call the API to start the login;
  API();
  return fetch("/api/v1/login", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      username: un,
      password: pw,
      remember: true,
    }),
  }).then(function (resp) {
    if (resp.status === 200) {
      return resp.json().then((res) => {
        if (res.success) {
          apiSelf.logged_in = true;
          apiSelf.token = res.token;
          localStorage.setItem("AUTH_TOKEN", apiSelf.token);
          apiSelf.on_login();
          return { success: true, message: "Logged in" };
        } else {
          return { success: false, message: res.message };
        }
      });
    } else {
      return { success: false, message: "Request Failed, unknown reason" };
    }
  });
}

export function detailed_update(deviceS, idx) {
  return new Promise((resolve, reject) => {
    //console.log(deviceS)
    deviceS.detailed_idx += 1;
    deviceS.loading = true;
    let active_idx = deviceS.detailed_idx;
    apiSelf.fetch_vehicle(deviceS.id).then(
      function (v) {
        //  console.log(v)
        if (active_idx != deviceS.detailed_idx) {
          return;
        }
        deviceS.loading = false;
        if (!v) {
          return;
        }
        //for now, we only care about the detailed tricon info
        deviceS.kind = v.kind; //tgs - for water transfer eval
        deviceS.j1939Time = v.j1939Time;
        deviceS.j1939Snapshot = v.j1939Snapshot;
        deviceS.j1939Snapshot.forEach((msg) => {
          msg.spns.forEach((spn) => {
            spn.text = spn.meaning ? spn.val.toFixed(0) : format_num(spn.val);
            if (spn.units) {
              spn.text += " " + spn.units;
            }
            if (spn.meaning) {
              spn.text += " - " + spn.meaning;
            }
          });
          msg.spns.sort((a, b) => (a.id < b.id ? -1 : 1));
        });
        deviceS.j1939Snapshot.sort((a, b) => {
          if (Number.isFinite(a.pgn_id) && Number.isFinite(b.pgn_id)) {
            return a.pgn_id < b.pgn_id ? -1 : 1;
          } else if (Number.isFinite(a.pgn_id)) {
            return -1;
          } else if (Number.isFinite(b.pgn_id)) {
            return 1;
          } else {
            return a.id.localeCompare(b.id);
          }
        });

        deviceS.triconTime = v.triconTime;

        //section order: ENG TRAN PUMP
        let sections = Object.keys(
          Object.values(v.tricon)
            .map((t) => t.section)
            .reduce((acc, s) => {
              acc[s] = 0;
              return acc;
            }, {})
        );
        let prefered_order = [
          "ENG",
          "TRAN",
          "PUMP",
          "AUX_ENG",
          "AUX_TRAN",
          "HYDR",
          "CPUMP",
          "CPUMP2",
          "CPUMP3",
          "CPUMP4",
          "BLOWER",
        ];
        sections.sort((a, b) => {
          let idx1 = prefered_order.indexOf(a);
          let idx2 = prefered_order.indexOf(b);
          //misc is always at the end
          if (a == "MISC") return 1;
          if (b == "MISC") return -1;
          //if neither is a known idx, don't sort
          if (idx1 < 0 && idx2 < 0) {
            return 0;
          }
          return idx1 < idx2 ? -1 : 1;
        });
        deviceS.sections = sections.map((section) => {
          let relValues = Object.values(v.tricon).filter(
            (t) => t.section == section
          );
          relValues.sort((t1, t2) => (t1.idx < t2.idx ? -1 : 1));
          return {
            name: relValues[0].niceSection,
            section: section,
            values: relValues.map((t) => ({
              idx: t.idx,
              name: t.niceName,
              text: t.text,
              is_config: t.idx >= 192,
              is_output: t.idx >= 128 && t.idx < 192,
              is_input: t.idx < 128,
            })),
          };
        });

        // console.log("xxxxx  STARTING  xxxxxx");
        let spnsAry = [];

        deviceS.j1939Snapshot.forEach((msg, ix1) => {
          // console.log("x msg.id: " + msg.id);

          msg.spns.forEach((spn, ix2) => {
            // spn.engine = [];
            // spn.transmission = [];
            // spn.pump = [];
            // spn.hydrolics = [];
            // spn.misc = []

            // console.log("spn.id: " + spn.id);

            // engine...
            if (spn.id === 190) {
              // console.log("x" + spn.id + " RPM");
              if (!Array.isArray(spnsAry.engine)) {
                spnsAry.engine = [];
              }
              spnsAry.engine.push({
                id: spn.id,
                sequence: 1,
                name: "RPM",
                value: format_num(spn.val),
                unit: spn.units,
                meaning: spn.meaning,
              });
              // console.log('!!!!!' + deviceS.j1939Snapshot.data.engine.engRPMName + ' SPN: ' + spn.id + ' VALUE: ' + deviceS.j1939Snapshot.data.engine.engRPMVal + ' ' + deviceS.j1939Snapshot.data.engine.engRPMUnit + ' ' + deviceS.j1939Snapshot.data.engine.meaning);
            }

            //testing rpm variable
            // if (spn.id === 190) {
            //   console.log('x' + spn.id + ' RPM');
            //   if (!Array.isArray(spnsAry.engine)) {
            //     spnsAry.engine = [];
            //   }
            //   const formattedRPM = format_num(spn.val);
            //   window.globalRPM = formattedRPM;  // Set the global variable
            //   spnsAry.engine.push({
            //     id: spn.id,
            //     sequence: 1,
            //     name: 'RPM',
            //     value: formattedRPM,
            //     unit: spn.units,
            //     meaning: spn.meaning
            //   });
            // }

            if (spn.id === 100) {
              // console.log("x" + spn.id + " OIL PRESSURE");
              if (!Array.isArray(spnsAry.engine)) {
                spnsAry.engine = [];
              }
              spnsAry.engine.push({
                id: spn.id,
                sequence: 2,
                name: "OIL PRESSURE",
                value: format_num(spn.val),
                unit: spn.units,
              });
              // console.log('!!!!!' + deviceS.j1939Snapshot.data.engine.engOilPress + ' SPN: ' + spn.id + ' VALUE: ' + deviceS.j1939Snapshot.data.engine.engOilPressVal + ' ' + deviceS.j1939Snapshot.data.engine.engOilPressUnit);
            }
            if (spn.id === 110) {
              // console.log("x" + spn.id + " COOLANT TEMP");
              if (!Array.isArray(spnsAry.engine)) {
                spnsAry.engine = [];
              }
              spnsAry.engine.push({
                id: spn.id,
                sequence: 3,
                name: "COOLANT TEMP",
                value: format_num(spn.val),
                unit: spn.units,
              });
              // console.log('!!!!!' + deviceS.j1939Snapshot.data.engine.engCoolantTemp + ' SPN: ' + spn.id + ' VALUE: ' + deviceS.j1939Snapshot.data.engine.engCoolantTempVal + ' ' + deviceS.j1939Snapshot.data.engine.engCoolantTempUnit);
            }
            //tgs - come back to fuel level - need info
            if (spn.id === 90001) {
              console.log("x" + spn.id + "FUEL LEVEL");
              if (!Array.isArray(spnsAry.engine)) {
                spnsAry.engine = [];
              }
              spnsAry.engine.push({
                id: spn.id,
                sequence: 4,
                name: "FUEL LEVEL",
                value: spn.val,
              });
              // console.log('!!!!!' + deviceS.j1939Snapshot.data.engine.engFuelLevel + ' SPN: ' + spn.id + ' VALUE: ' + deviceS.j1939Snapshot.data.engine.engFuelLevelVal);
            }
            if (spn.id === 174) {
              // console.log("x" + spn.id + "FUEL TEMP");
              if (!Array.isArray(spnsAry.engine)) {
                spnsAry.engine = [];
              }
              spnsAry.engine.push({
                id: spn.id,
                sequence: 5,
                name: "FUEL TEMP",
                value: spn.val,
              });
              // console.log('!!!!!' + deviceS.j1939Snapshot.data.engine.engFuelTemp + ' SPN: ' + spn.id + ' VALUE: ' + deviceS.j1939Snapshot.data.engine.engFuelTempVal);
            }

            if (spn.id === 183) {
              // console.log("x" + spn.id + " FUEL RATE");
              if (!Array.isArray(spnsAry.engine)) {
                spnsAry.engine = [];
              }
              spnsAry.engine.push({
                id: spn.id,
                sequence: 6,
                name: "FUEL RATE",
                value: format_num(spn.val),
              });
              // console.log('!!!!!' + deviceS.j1939Snapshot.data.engine.engFuelRate + ' SPN: ' + spn.id + ' VALUE: ' + deviceS.j1939Snapshot.data.engine.engFuelRateVal);
            }
            if (spn.id === 94) {
              // console.log("x" + spn.id + " FUEL PRESSURE");
              if (!Array.isArray(spnsAry.engine)) {
                spnsAry.engine = [];
              }
              spnsAry.engine.push({
                id: spn.id,
                sequence: 7,
                name: "FUEL PRESSURE",
                value: format_num(spn.val),
                unit: spn.units,
              });
              // console.log('!!!!!' + deviceS.j1939Snapshot.data.engine.engFuelPress + ' SPN: ' + spn.id + ' VALUE: ' + deviceS.j1939Snapshot.data.engine.engFuelPressVal);
            }
            if (spn.id === 102) {
              // console.log("x" + spn.id + " INTAKE MANIFOLD PRESSURE");
              if (!Array.isArray(spnsAry.engine)) {
                spnsAry.engine = [];
              }
              spnsAry.engine.push({
                id: spn.id,
                sequence: 8,
                name: "INTAKE MANIFOLD PRESSURE",
                value: format_num(spn.val) ?? "UNAVAILABLE",
              });
              // console.log('!!!!!' + deviceS.j1939Snapshot.data.engine.engIntakeManPress + ' SPN: ' + spn.id + ' VALUE: ' + deviceS.j1939Snapshot.data.engine.engIntakeManPressVal);
            }
            if (spn.id === 105) {
              // console.log("x" + spn.id + " INTAKE MANIFOLD TEKP");
              if (!Array.isArray(spnsAry.engine)) {
                spnsAry.engine = [];
              }
              spnsAry.engine.push({
                id: spn.id,
                sequence: 9,
                name: "INTAKE MANIFOLD TEMP",
                value: format_num(spn.val) ?? "UNAVAILABLE",
              });
              // console.log('!!!!!' + deviceS.j1939Snapshot.data.engine.engIntakeManTemp + ' SPN: ' + spn.id + ' VALUE: ' + deviceS.j1939Snapshot.data.engine.engIntakeManTempVal);
            }
            if (spn.id === 168) {
              // console.log("x" + spn.id + " VOLTAGE");
              if (!Array.isArray(spnsAry.engine)) {
                spnsAry.engine = [];
              }
              spnsAry.engine.push({
                id: spn.id,
                sequence: 10,
                name: "VOLTAGE",
                value: format_num(spn.val) ?? "UNAVAILABLE",
              });
              // console.log('!!!!!' + deviceS.j1939Snapshot.data.engine.engVoltage + ' SPN: ' + spn.id + ' VALUE: ' + deviceS.j1939Snapshot.data.engine.engVoltageVal);
            }
            if (spn.id === 92) {
              // console.log("x" + spn.id + " LOAD %");
              if (!Array.isArray(spnsAry.engine)) {
                spnsAry.engine = [];
              }
              spnsAry.engine.push({
                id: spn.id,
                sequence: 11,
                name: "LOAD %",
                value: format_num(spn.val) ?? "UNAVAILABLE",
              });
              // console.log('!!!!!' + deviceS.j1939Snapshot.data.engine.engLoadPct + ' SPN: ' + spn.id + ' VALUE: ' + deviceS.j1939Snapshot.data.engine.engLoadPctVal);
            }
            if (spn.id === 247) {
              // console.log("x" + spn.id + " HOURS");
              if (!Array.isArray(spnsAry.engine)) {
                spnsAry.engine = [];
              }
              spnsAry.engine.push({
                id: spn.id,
                sequence: 12,
                name: "HOURS",
                value: format_num(spn.val) ?? "UNAVAILABLE",
              });
              // console.log('!!!!!' + deviceS.j1939Snapshot.data.engine.engHours + ' SPN: ' + spn.id + ' VALUE: ' + deviceS.j1939Snapshot.data.engine.engHoursVal);
            }
            if (spn.id === 91) {
              // console.log("x" + spn.id + " THROTTLE");
              if (!Array.isArray(spnsAry.engine)) {
                spnsAry.engine = [];
              }
              spnsAry.engine.push({
                id: spn.id,
                sequence: 13,
                name: "THROTTLE",
                value: format_num(spn.val) ?? "UNAVAILABLE",
              });
              // console.log('!!!!!' + deviceS.j1939Snapshot.data.engine.engThrottle + ' SPN: ' + spn.id + ' VALUE: ' + deviceS.j1939Snapshot.data.engine.engThrottleVal);
            }
            // transmission
            if (spn.id === 127) {
              // console.log("x" + spn.id + " OIL PRESSURE");
              if (!Array.isArray(spnsAry.transmission)) {
                spnsAry.transmission = [];
              }
              spnsAry.transmission.push({
                id: spn.id,
                sequence: 1,
                name: "OIL PRESSURE",
                value: format_num(spn.val) ?? "UNAVAILABLE",
              });
              // console.log('!!!!!' + deviceS.j1939Snapshot.data.transmission.tranOilPress + ' SPN: ' + spn.id + ' VALUE: ' + deviceS.j1939Snapshot.data.transmission.tranOilPressVal);
            }
            if (spn.id === 177) {
              // console.log("x" + spn.id + " OIL TEMP");
              if (!Array.isArray(spnsAry.transmission)) {
                spnsAry.transmission = [];
              }
              spnsAry.transmission.push({
                id: spn.id,
                sequence: 2,
                name: "OIL TEMP",
                value: format_num(spn.val) ?? "UNAVAILABLE",
              });
              // console.log('!!!!!' + deviceS.j1939Snapshot.data.transmission.tranOilTemp + ' SPN: ' + spn.id + ' VALUE: ' + deviceS.j1939Snapshot.data.transmission.tranOilTempVal);
            }
            //tgs - come back to this one
            if (spn.id === 523) {
              // console.log("x" + spn.id + " GEAR");
              if (!Array.isArray(spnsAry.transmission)) {
                spnsAry.transmission = [];
              }
              spnsAry.transmission.push({
                id: spn.id,
                sequence: 3,
                name: "GEAR",
                value: format_num(spn.val) ?? "UNAVAILABLE",
              });
              // console.log('!!!!!' + deviceS.j1939Snapshot.data.transmission.tranGear + ' SPN: ' + spn.id + ' VALUE: ' + deviceS.j1939Snapshot.data.transmission.tranGearVal);
            }
            //come back to this one
            if (spn.id === 573) {
              // console.log("x" + spn.id + " LOCK-UP");
              if (!Array.isArray(spnsAry.transmission)) {
                spnsAry.transmission = [];
              }
              spnsAry.transmission.push({
                id: spn.id,
                sequence: 4,
                name: "LOCK-UP",
                value: format_num(spn.val) ?? "UNAVAILABLE",
              });
              // console.log('!!!!!' + deviceS.j1939Snapshot.data.transmission.tranLockUp + ' SPN: ' + spn.id + ' VALUE: ' + deviceS.j1939Snapshot.data.transmission.tranLockUpVal);
            }
            //come back to this one
            if (spn.id === 161) {
              // console.log("x" + spn.id + " INPUT RPM");
              if (!Array.isArray(spnsAry.transmission)) {
                spnsAry.transmission = [];
              }
              spnsAry.transmission.push({
                id: spn.id,
                sequence: 5,
                name: "INPUT RPM",
                value: format_num(spn.val) ?? "UNAVAILABLE",
              });
              // console.log('!!!!!' + deviceS.j1939Snapshot.data.transmission.tranInputRPM + ' SPN: ' + spn.id + ' VALUE: ' + deviceS.j1939Snapshot.data.transmission.tranInputRPMVal);
            }
            if (spn.id === 191) {
              // console.log("x" + spn.id + " OUTPUT RPM");
              if (!Array.isArray(spnsAry.transmission)) {
                spnsAry.transmission = [];
              }
              spnsAry.transmission.push({
                id: spn.id,
                sequence: 6,
                name: "OUTPUT RPM",
                value: format_num(spn.val) ?? "UNAVAILABLE",
              });
              // console.log('!!!!!' + deviceS.j1939Snapshot.data.transmission.tranOutputRPM + ' SPN: ' + spn.id + ' VALUE: ' + deviceS.j1939Snapshot.data.transmission.tranOutputRPMVal);
            }
            // Pump
            if (spn.id === 90003) {
              // console.log("x" + spn.id + " WORKING PRESSURE");
              if (!Array.isArray(spnsAry.pump)) {
                spnsAry.pump = [];
              }
              spnsAry.pump.push({
                id: spn.id,
                sequence: 1,
                name: "WORKING PRESSURE",
                value: format_num(spn.val) ?? "UNAVAILABLE",
              });
              // console.log('!!!!!' + deviceS.j1939Snapshot.data.transmission.tranOutputRPM + ' SPN: ' + spn.id + ' VALUE: ' + deviceS.j1939Snapshot.data.transmission.tranOutputRPMVal);
            }
            if (spn.id === 90004) {
              // console.log("x" + spn.id + " RATE");
              if (!Array.isArray(spnsAry.pump)) {
                spnsAry.pump = [];
              }
              spnsAry.pump.push({
                id: spn.id,
                sequence: 2,
                name: "RATE",
                value: format_num(spn.val) ?? "UNAVAILABLE",
              });
              // console.log('!!!!!' + deviceS.j1939Snapshot.data.transmission.tranOutputRPM + ' SPN: ' + spn.id + ' VALUE: ' + deviceS.j1939Snapshot.data.transmission.tranOutputRPMVal);
            }
            // todo ref idx 136 --Scott Sr
            if (spn.id === 77777) {
              // console.log("x" + spn.id + " TOTAL");
              if (!Array.isArray(spnsAry.pump)) {
                spnsAry.pump = [];
              }
              spnsAry.pump.push({
                id: spn.id,
                sequence: 3,
                name: "TOTAL",
                value: format_num(spn.val) ?? "UNAVAILABLE",
              });
              // console.log('!!!!!' + deviceS.j1939Snapshot.data.transmission.tranOutputRPM + ' SPN: ' + spn.id + ' VALUE: ' + deviceS.j1939Snapshot.data.transmission.tranOutputRPMVal);
            }
            if (spn.id === 90002) {
              // console.log("x" + spn.id + " SUCTION PRESSURE");
              if (!Array.isArray(spnsAry.pump)) {
                spnsAry.pump = [];
              }
              spnsAry.pump.push({
                id: spn.id,
                sequence: 4,
                name: "SUCTION PRESSUE",
                value: format_num(spn.val) ?? "UNAVAILABLE",
              });
              // console.log('!!!!!' + deviceS.j1939Snapshot.data.transmission.tranOutputRPM + ' SPN: ' + spn.id + ' VALUE: ' + deviceS.j1939Snapshot.data.transmission.tranOutputRPMVal);
            }
            // todo ref idx 135 --Scott Sr
            if (spn.id === 77777) {
              // console.log("x" + spn.id + " KICK OUT");
              if (!Array.isArray(spnsAry.pump)) {
                spnsAry.pump = [];
              }
              spnsAry.pump.push({
                id: spn.id,
                sequence: 5,
                name: "KICK OUT",
                value: format_num(spn.val) ?? "UNAVAILABLE",
              });
              // console.log('!!!!!' + deviceS.j1939Snapshot.data.transmission.tranOutputRPM + ' SPN: ' + spn.id + ' VALUE: ' + deviceS.j1939Snapshot.data.transmission.tranOutputRPMVal);
            }
            // MISC
            if (spn.id === 77777) {
              // console.log("x" + spn.id + " CPU TEMP");
              if (!Array.isArray(spnsAry.misc)) {
                spnsAry.misc = [];
              }
              spnsAry.misc.push({
                id: spn.id,
                sequence: 1,
                name: "CPU TEMP",
                value: format_num(spn.val) ?? "UNAVAILABLE",
              });
              // console.log('!!!!!' + deviceS.j1939Snapshot.data.transmission.tranOutputRPM + ' SPN: ' + spn.id + ' VALUE: ' + deviceS.j1939Snapshot.data.transmission.tranOutputRPMVal);
            }
          });
        });

        if (!Array.isArray(spnsAry.misc)) {
          spnsAry.misc = [];
        }
        spnsAry.misc.push({
          id: 777,
          sequence: 1,
          name: "CPU TEMP",
          value: format_num(null) ?? "UNAVAILABLE",
        });
        // console.log('!!!!!' + deviceS.j1939Snapshot.data.transmission.tranOutputRPM + ' SPN: ' + spn.id + ' VALUE: ' + deviceS.j1939Snapshot.data.transmission.tranOutputRPMVal);

        if (spnsAry) {
          if (spnsAry.engine) {
            spnsAry.engine.sort((a, b) => a.sequence - b.sequence);
          }
          if (spnsAry.pump) {
            spnsAry.pump.sort((a, b) => a.sequence - b.sequence);
          }
          if (spnsAry.transmissioon) {
            spnsAry.transmision.sort((a, b) => a.sequence - b.sequence);
          }
          // debugger;
          deviceS.j1939Snapshot.spnsAry = spnsAry;
        }

        resolve(deviceS);
      },
      (e) => {
        reject(e);
        deviceS.loading = false;
        console.error(e);
      }
    );
  });
}

export async function send_stopped(id) {
  return apiSelf
    .do_gql_request(
      `
    mutation ($id: Int!) {
      vehicle(id: $id) {
        waterTransferCommand(cmd: { state: STOPPED, targets: [{kind: RPM, value: 0}], limits: [{kind: DISCHARGE, value: 0}]}) {
          id
        }
      }
    }
  `,
      { id: id }
    )
    .then(function (resp) {
      if (resp.status === 200) {
        return resp.json().then((raw_results) => {
          // const readings = raw_results.data.vehicle.waterTransferCommand.id;
          return {
            vehicle: raw_results.data.vehicle,
          };
        });
      } else if (resp.status === 401) {
        return Promise.reject("UNAUTH");
      } else {
        return Promise.reject("ERROR");
      }
    });
}

export async function send_running(id) {
  return apiSelf
    .do_gql_request(
      `
    mutation ($id: Int!) {
      vehicle(id: $id) {
        waterTransferCommand(cmd: { state: RUNNING, targets: [{kind: RPM, value: 600}], limits: [{kind: DISCHARGE, value: 50 }]}) {
          id
        }
      }
    }
  `,
      { id: id }
    )
    .then(function (resp) {
      if (resp.status === 200) {
        return resp.json().then((raw_results) => {
          // const readings = raw_results.data.vehicle.waterTransferCommand.id;
          return {
            vehicle: raw_results.data.vehicle,
          };
        });
      } else if (resp.status === 401) {
        return Promise.reject("UNAUTH");
      } else {
        return Promise.reject("ERROR");
      }
    });
}

export async function send_throttle(id, rpmValue, wtLastCmdDischargeValue) {
  // Added rpmValue as a parameter
  return apiSelf
    .do_gql_request(
      `
    mutation ($id: Int!, $rpmValue: Float!, $wtLastCmdDischargeValue: Float!) {
      vehicle(id: $id) {
        waterTransferCommand(cmd: { state: RUNNING, targets: [{kind: RPM, value: $rpmValue}], limits: [{kind: DISCHARGE, value: $wtLastCmdDischargeValue }]}) {
          id
        }
      }
    }
  `,
      {
        id: id,
        rpmValue: rpmValue,
        wtLastCmdDischargeValue: wtLastCmdDischargeValue,
      }
    )
    .then(function (resp) {
      if (resp.status === 200) {
        return resp.json().then((raw_results) => {
          return {
            vehicle: raw_results.data.vehicle,
          };
        });
      } else if (resp.status === 401) {
        return Promise.reject("UNAUTH");
      } else {
        return Promise.reject("ERROR");
      }
    });
}

export async function send_maxpressure(id, rpmValue, maxPressureValue) {
  return apiSelf
    .do_gql_request(
      `
      mutation ($id: Int!, $rpmValue: Float!, $maxPressureValue: Float!) {
        vehicle(id: $id) {
          waterTransferCommand(cmd: { state: RUNNING, targets: [{kind: RPM, value: $rpmValue}], limits: [{kind: DISCHARGE, value: $maxPressureValue }]}) {
            id
          }
        }
      }
  `,
      { id: id, rpmValue: rpmValue, maxPressureValue: maxPressureValue } // Pass rpmValue to the query variables
    )
    .then(function (resp) {
      if (resp.status === 200) {
        return resp.json().then((raw_results) => {
          return {
            vehicle: raw_results.data.vehicle,
          };
        });
      } else if (resp.status === 401) {
        return Promise.reject("UNAUTH");
      } else {
        return Promise.reject("ERROR");
      }
    });
}

export async function send_off(id) {
  return apiSelf
    .do_gql_request(
      `
    mutation ($id: Int!) {
      vehicle(id: $id) {
        waterTransferCommand(cmd: { state: OFF, targets: [{kind: RPM, value: 0}], limits: [{kind: DISCHARGE, value: 0 }]}) {
          id
        }
      }
    }
  `,
      { id: id }
    )
    .then(function (resp) {
      if (resp.status === 200) {
        return resp.json().then((raw_results) => {
          // const readings = raw_results.data.vehicle.waterTransferCommand.id;
          return {
            vehicle: raw_results.data.vehicle,
          };
        });
      } else if (resp.status === 401) {
        return Promise.reject("UNAUTH");
      } else {
        return Promise.reject("ERROR");
      }
    });
}

export async function fetch_watertransfer(id) {
  return apiSelf
    .do_gql_request(
      `
  query ($id: ID!) {
    vehicle(id: $id) {
      waterTransfer {
        mode state
        runningCommand { state ackd }
        lastCommand {  state ackd target {kind value} limit { kind value } }
        readings { name value }
      }
    }
  }`,
      { id: id }
    )
    .then(function (resp) {
      if (resp.status === 200) {
        return resp.json().then((raw_results) => {
          const readings = raw_results.data.vehicle.waterTransfer.readings;

          for (let reading of readings) {
            reading = [
              { name: "tgt_rpm", value: null },
              { name: "offset_rpm", value: null },
              { name: "discharge", value: null },
              { name: "suction", value: null },
              { name: "rate", value: null },
              { name: "fuel_lvl", value: null },
              { name: "local_rpm_req", value: null },
              { name: "eng_rpm", value: null },
            ];
          }

          const readingsByName = {};

          readings.forEach((reading) => {
            // Assign each reading to the object using its name as the key
            readingsByName[reading.name] = reading.value;
          });

          let state = "";
          let ackd = "";
          let discharge = "";
          let rpm = "";
          let runningCommand = "";

          if (raw_results.data.vehicle.waterTransfer.lastCommand) {
            state = raw_results.data.vehicle.waterTransfer.lastCommand.state;
            ackd = raw_results.data.vehicle.waterTransfer.lastCommand.ackd;
            discharge =
              raw_results.data.vehicle.waterTransfer.lastCommand.limit[0].value;
            rpm =
              raw_results.data.vehicle.waterTransfer.lastCommand.target[0]
                .value;
          }

          return {
            mode: raw_results.data.vehicle.waterTransfer.mode,
            state: raw_results.data.vehicle.waterTransfer.state,
            runningCommand:
              raw_results.data.vehicle.waterTransfer.runningCommand,
            lastCmdState: state,
            lastCmdAckd: ackd,
            lastCmdTgtRpm: rpm,
            lastCmdLmtDischarge: discharge,
            readingTgtRpm: readingsByName["tgt_rpm"], // Use the name as the key
            readingOffsetRpm: readingsByName["offset_rpm"],
            readingEngRpm: readingsByName["eng_rpm"],
            readingLocalRpmReq: readingsByName["local_rpm_req"],
            readingDischarge: readingsByName["discharge"],
            readingSuction: readingsByName["suction"],
            readingRate: readingsByName["rate"],
            readingFuelLvl: readingsByName["fuel_lvl"],
          };
        });
      } else if (resp.status === 401) {
        return Promise.reject("UNAUTH");
      } else {
        return Promise.reject("ERROR");
      }
    });
}

export function get_data(device) {
  return {
    series: [device["history_" + device.data_src] ?? []],
    labels: device.history_labels.map(
      (l) =>
        (device.ref_time ?? moment.utc()).diff(l, "minutes").toFixed(0) + "m"
    ),
  };
}

export async function App() {
  new API();

  appSelf.updateId = 0;
  appSelf.user = new User(appSelf);
  appSelf.devices = [];
  appSelf.loading = false;

  if (!apiSelf.logged_in) {
    if (BrowserHistory.location.pathname.indexOf("/auth/login") < 0) {
      BrowserHistory.push("/auth/login");
    }
  }

  appSelf.active_refresh = 0;

  if (apiSelf.logged_in) {
    try {
      await refresh_vehicles();
    } catch (e) {
      console.error("Could not refresh vehicles: ", e);
    }
  }

  return appSelf;
}

export async function get_series_select(id, startDate) {
  return apiSelf
    .do_gql_request(
      `
    query seriesselect($id: ID!, $start: DateTime!) { 
      vehicle(id: $id) {
        id
        name
        asset {
          availableSeries(start: $start) {
            key
            spn { spn name }
          }
        }
      }
    }
    `,
      {
        id: id,
        start: startDate,
      }
    )
    .then(function (resp) {
      if (resp.status === 200) {
        return resp.json().then((raw_results) => {
          if (
            raw_results.data &&
            raw_results.data.vehicle &&
            raw_results.data.vehicle.asset
          ) {
            return raw_results.data.vehicle.asset.availableSeries;
          } else {
            return Promise.reject("NO_DATA");
          }
        });
      } else if (resp.status === 401) {
        return Promise.reject("UNAUTH");
      } else {
        return Promise.reject("ERROR");
      }
    });
}
let errorMessages = [];

export async function get_historical_data(
  id,
  key,
  startDate,
  endDtate,
  interval
) {
  return apiSelf
    .do_gql_request(
      `query get_data {
          vehicle(id: ${id}) {
            asset {
              data(
                input: {
                  keys: ["${key}"],
                  start: "${startDate}",
                  end:"${endDtate}"
                  intervalS: ${interval},
                  pivot: false,
                  fillMissing: true,
                  func: MEAN
                }
                ) {
                info {
                  key
                  name
                  units
                }
                data
              }
            }
          }
        }
     `,
      {}
    )
    .then(function (resp) {
      if (resp.status === 200) {
        return resp.json().then((raw_results) => {
          if (raw_results.errors && raw_results.errors.length > 0) {
            throw new Error(raw_results.errors[0].message);
          }
          // rest of your processing logic here
          return {
            id: id,
            historical_data: raw_results.data.vehicle.asset.data,
          };
        });
      } else if (resp.status === 401) {
        throw new Error("UNAUTH");
      } else {
        throw new Error("HTTP ERROR " + resp.status);
      }
    })
    .catch((error) => {
      if (error.message === "Query may return too many results") {
     //   window.alert("Query may return too many results - Select a longer time interval.");
        return "error"

      } else {
        window.alert(error.message);
      }
      console.log("An error occurred:", error.message);
      return;
    });
}
