import React, { useState, useEffect } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl,
  Modal,
} from "react-bootstrap";

import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import Checkbox from "components/CustomCheckbox/CustomCheckbox.jsx";
import { try_login } from "../../models/api";

import BrowserHistory from "models/BrowserHistory";

const LoginPage = () => {
  const [cardHidden, setCardHidden] = useState(true);
  const [showForgotModal, setShowForgotModal] = useState(false);
  const [formData, setFormData] = useState({});
  const [error, setError] = useState("");

  useEffect(() => {
    setTimeout(() => {
      setCardHidden(false);
    }, 700);
  }, []);

  const handleChange = (evt) => {
    const value = evt.target.name === "remember" ? evt.target.checked : evt.target.value;
    setFormData({ ...formData, [evt.target.name]: value });
  };

  const handleLogin = async (evt) => {
    evt.preventDefault();
    try {
      const result = await try_login(formData.email, formData.password);
      if (result.success) {
        BrowserHistory.push("/admin/dashboard");
        // setError("");
      } else {
        setError(result.message);
      }
    } catch (err) {
      setError("Unknown error");
    }
  };

  const handleForgotPassword = (evt) => {
    evt.preventDefault();
    // console.log(`Forgot password clicked!!! ${formData.email}`);
    // Implement forgot password logic here
  };

  return (
    <Grid>
      <Row>
        <Col md={4} sm={6} mdOffset={4} smOffset={3} >
          <form onSubmit={handleLogin} method={"POST"}>
            <Card
              hidden={cardHidden}
              textCenter
              title={<span className={"login-title"}>Login</span>}
              content={
                <div className="login-container">
                  <FormGroup>
                    <ControlLabel>Email address</ControlLabel>
                    <FormControl name="email" placeholder="Enter email" type="email" onChange={handleChange} />
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel>Password</ControlLabel>
                    <FormControl name="password" placeholder="Password" type="password" autoComplete="off" onChange={handleChange} />
                  </FormGroup>
                  <FormGroup>
                    <Checkbox name="remember" number="1" label="Remember Me" onChange={handleChange} />
                  </FormGroup>
                  { /*
                    <FormGroup>
                      <a href="#" onClick={(e)=> { e.preventDefault(); this.setState({showForgotModal: true}); }}>Forgot Password?</a>
                    </FormGroup>
                    */ }
                  {error ? (<div className={"alert alert-danger"}>{error}</div>) : ("")}
                  <input type="submit" style={{ "display": "none" }} />
                </div>
              }
              legend={
                <Button bsStyle="info" fill wd onClick={handleLogin}>
                  Login
                </Button>
              }
              ftTextCenter
            />
          </form>
          <Modal show={showForgotModal} onHide={() => setShowForgotModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Forgot Password</Modal.Title>
              <Modal.Body>
                <form onSubmit={handleForgotPassword} method={"POST"}>
                  <FormGroup>
                    <ControlLabel>Email address</ControlLabel>
                    <FormControl name="email" placeholder="Enter email" type="email" onChange={handleChange} />
                  </FormGroup>
                  <input type="submit" style={{ "display": "none" }} />
                </form>
              </Modal.Body>
            </Modal.Header>
            <Modal.Footer>
              <Button simple onClick={() => setShowForgotModal(false)}>Cancel</Button>
              <Button simple onClick={handleForgotPassword}>Reset Password</Button>
            </Modal.Footer>
          </Modal>
        </Col>
      </Row>
    </Grid>
  );
};

export default LoginPage;

